import Flames from "components/fire/Flames";
import DialogWindow from "components/other/DialogWindow";
import MammothIcon from "icons/MammothIcon";
import React from "react";
import { Trans } from "react-i18next";
import styled, { css } from "styled-components/macro";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RewardsList from "./RewardsList";
import { getLevel } from "../levelsHelper";
import RewardComponent from "./RewardComponent";
import { T_LOSING_XPS } from "sharedJs__generated/constants";
import { useEvent } from "react-use";
import { GardenXps } from "../Garden";
import { EVENT } from "tools/events";
import { useViewerQuery } from "../../../queries/viewerQuery";

export enum Epoch {
  gold = "gold",
  titan = "titan"
}

export const coloringFilters = css`
  .${Epoch.gold} {
    filter: sepia(1) saturate(2.6) brightness(1.1) drop-shadow(0.5px 0.5px 2px #0002);
    transform: translateZ(0); // fix ios filter-rendering
  }
  .${Epoch.titan} {
    filter: sepia(1) saturate(7) hue-rotate(190deg) brightness(0.7) drop-shadow(0.5px 0.5px 2px #0002);
    transform: translateZ(0); // fix ios filter-rendering
  }
`;

const Wrap = styled.div`
  padding: 10px;
  ${coloringFilters}
`;
const SvgWrap = styled.div`
  margin: 15px auto 20px;
  svg {
    position: static;
    width: 60px;
    height: 60px;
  }
  .flamesExtraClass {
    transform: scale(1.3);
    margin: 0 auto;
  }
`;
const SFlames = styled(Flames)`
  margin: 0px 4px -6px 2px;
  display: inline-block;
`;
const KeepInMind = styled.div`
  font-size: 13px;
  line-height: 1.4em;
  color: #888;
`;
const GoldenMammoth = styled(MammothIcon)`
  filter: sepia(1) saturate(3) hue-rotate(0deg) brightness(1.1);
  width: 70px;
  height: 70px;
`;
const RewardsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  font-size: 13px;
  cursor: pointer;
`;

type Props = {
  items: React.ReactNode[];
  nextItemIndex: number;
  xps: GardenXps;
};
const NextItemDialog: React.FC<Props> = ({ items, nextItemIndex, xps }) => {
  const [open, setOpen] = React.useState(false);
  const [openRewards, setOpenRewards] = React.useState(false);

  const openFn = React.useCallback(() => setOpen(true), []);
  useEvent(EVENT.openNextItemDialog, openFn);

  const nextItem = items[nextItemIndex];
  const { progressXps, nextLevelXps } = getLevel(xps.total);
  const { getFlag } = useViewerQuery();
  const abtest_loosingXpRemoved = getFlag("abtest_loosingXpRemoved")?.value;

  return (
    <DialogWindow open={open} onClose={() => setOpen(false)}>
      <Wrap>
        {nextItem ? (
          <>
            <Trans>
              Gain <SFlames strength={5} xps={(nextLevelXps || 0) - (progressXps || 0)} /> to upgrade Memo’s home with:
            </Trans>{" "}
            <SvgWrap>
              <RewardComponent component={nextItem} itemIndex={nextItemIndex} />
            </SvgWrap>
            {abtest_loosingXpRemoved !== "on" && <Trans parent={KeepInMind}>{T_LOSING_XPS}</Trans>}
          </>
        ) : (
          <>
            <GoldenMammoth />
            <Trans parent="p">There are no more improvements on Memo's home. Congratulation!</Trans>
          </>
        )}
        <RewardsButton onClick={() => setOpenRewards(!openRewards)}>
          <Trans>Rewards list</Trans> <ArrowDropDownIcon />
        </RewardsButton>
        {openRewards && <RewardsList xps={xps} items={items} nextItemIndex={nextItemIndex} />}
      </Wrap>
    </DialogWindow>
  );
};

export default NextItemDialog;
