import React from "react";
import styled from "styled-components";
import SelectList, { SelectItem } from "./SelectList";
import { QuestionData } from "./questions";
import useOnboardingQuestions from "hooks/useOnboardingQuestions";
import { useViewerQuery } from "queries/viewerQuery";
type Props = QuestionData & {
  onCanSubmitChange?: (canSubmit: boolean) => void;
  onRequestSubmit?: () => void;
};

const ListWrap = styled.div`
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-y: auto;
  position: relative;
  margin-top: 5px;
`;

const OnboardingQuestion: React.FC<Props> = ({
  id,
  visualType,
  options,
  multipleAnswers,
  onCanSubmitChange,
  onRequestSubmit
}) => {
  const { saveQuestion, getAnswers } = useOnboardingQuestions();
  const [initialSelectedIds, setInitialSelectedIds] = React.useState<string[]>([]);
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const { getFlag } = useViewerQuery();
  const autoContinue = getFlag("abtest_onboardingAutoContinue")?.value === "on";

  const handleAnswerChange = (selectedAnswers: SelectItem[]) => {
    const selectedIds = selectedAnswers.map((item) => item.id);
    onCanSubmitChange?.(selectedIds.length > 0);
    saveQuestion({
      questionId: id,
      answers: selectedIds
    });

    if (!multipleAnswers && autoContinue) {
      onRequestSubmit?.();
    }
  };

  React.useEffect(() => {
    const answers = getAnswers(id);
    onCanSubmitChange?.(answers.length > 0);
    setInitialSelectedIds(answers);
  }, [getAnswers, id, onCanSubmitChange]);

  React.useEffect(() => {
    wrapRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  return (
    <ListWrap ref={wrapRef}>
      <SelectList
        layoutMode={visualType}
        items={options}
        onChange={handleAnswerChange}
        multiSelect={multipleAnswers}
        initialSelectedIds={initialSelectedIds}
      />
    </ListWrap>
  );
};

export default OnboardingQuestion;
