import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { useNavigate } from "react-router";
import { useEvent } from "react-use";
import { EVENT } from "tools/events";
import { SHOW_SOURCES_QUEST } from "./HelpFindCave";
import { OWNWORDQUEST } from "./OwnWord";
import DialogWindow from "components/other/DialogWindow";
import { Trans } from "react-i18next";
import { Button } from "@mui/material";

const OwnWordRewardDialog = () => {
  const { hintWasSeen } = useViewerQuery();
  const [open, setOpen] = React.useState<undefined | boolean>(undefined);
  const navigate = useNavigate();

  useEvent(EVENT.openOwnWordDialog, () => {
    if (open === undefined) setOpen(true); // open dialog only once
  });

  if (hintWasSeen(SHOW_SOURCES_QUEST) && !hintWasSeen(OWNWORDQUEST)) {
    return (
      <DialogWindow open={!!open}>
        <div style={{ padding: "30px 0 0" }}>
          <img style={{ width: "100px", height: "100px" }} src="https://cdn.duocards.com/svg/confetti.svg" alt="" />
          <Trans parent="p">Excelent!</Trans>
          <Trans parent="p">Your first card created completely on your own.</Trans>
          <div>
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: "10px 0 10px" }}
              onClick={() => {
                navigate(-1);
                setOpen(false);
              }}
            >
              <Trans>Get a reward</Trans>
            </Button>
          </div>
          <Button onClick={() => setOpen(false)}>
            <Trans>Add more words</Trans>
          </Button>
        </div>
      </DialogWindow>
    );
  }
  return null;
};

export default OwnWordRewardDialog;
