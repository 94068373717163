import React, { useRef } from "react";
import { Trans } from "react-i18next";
import styled, { keyframes } from "styled-components/macro";
import { Button, Slide } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import { SUBSCRIPTION } from "../../profile/ProfileRouter.config";
import { useNavigate } from "react-router";
import { useViewerQuery } from "queries/viewerQuery";
import FeaturesHintAnimation, { Feature } from "./FeaturesHintAnimation";
import { useGetState, useSetState } from "components/ReduxProvider";
import DialogFullWindow from "components/other/DialogFullWindow";
import { MOTIVATIONHINT } from "sharedJs__generated/constants";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const features: Feature[] = [
  {
    title: "Add new words via the share button",
    rive: "https://imgs.duocards.com/rive/onboarding-web.riv"
  },
  {
    title: "Create custom cards in the app",
    rive: "https://imgs.duocards.com/rive/onboarding-add.riv",
    texts: [
      {
        id: "Run-Original",
        text: "Rabbit",
        translate: "front"
      },
      {
        id: "Run-Translate",
        text: "Rabbit",
        translate: "back"
      },
      {
        id: "Run-Save",
        text: "Save",
        translate: "app"
      }
    ]
  },
  {
    title: "Add words from a PC via Chrome Extensions",
    rive: "https://imgs.duocards.com/rive/onboarding-chrome.riv",
    texts: [
      {
        id: "Run-Original",
        text: "Word",
        translate: "front"
      },
      {
        id: "Run-Translate",
        text: "Word",
        translate: "back"
      }
    ]
  },
  {
    title: "Learn grammar with our AI assistant",
    rive: "https://imgs.duocards.com/rive/onboarding-chat.riv",
    texts: [
      {
        id: "RunQuestion",
        text: "Question",
        translate: "front"
      },
      {
        id: "RunAnswer",
        text: "Answer",
        translate: "front"
      }
    ]
  }
];

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: calc(min(78px, 13vw));
  padding-right: calc(min(78px, 13vw));
`;

const STitle = styled.div`
  text-align: center;
  font-size: calc(20px + 1vw);
  font-weight: 600;
  margin: 0 -15px 20px;
  line-height: 1.2;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out 0s forwards;
`;

const AnimWrap = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 50%;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out 0.6s forwards;
`;

const SButton = styled(Button)`
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out 1.2s forwards;
  width: 100%;
  margin-bottom: calc(min(44px, 13vh));
  margin-top: calc(min(44px, 13vh));
`;
type Props = {};

const FeaturesHint: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { viewer, isSubscriber } = useViewerQuery();
  const seeHint = useSeeHint();
  const setFeatureIndex = useSetState("onboardingFeaturesScreenIndex");
  const featureIndex = useGetState("onboardingFeaturesScreenIndex");
  const animationRef = useRef<{ startAnimation: () => void }>(null);

  const handleNextClick = () => {
    const currentIndex = featureIndex ?? 0;
    if (currentIndex < features.length - 1) {
      setFeatureIndex(currentIndex + 1);
      return;
    }

    seeHint(MOTIVATIONHINT);
    if (!viewer?.inviter && !isSubscriber) {
      navigate(SUBSCRIPTION.url());
    }
  };

  const handleAnimWrapFadedIn = () => {
    animationRef.current?.startAnimation();
  };

  const feature = features[featureIndex ?? 0];

  return (
    <DialogFullWindow open={true} background={true} TransitionComponent={Slide} maxWidth={600}>
      <ModalContent key={`content-${featureIndex}`}>
        <div />
        <STitle>
          <Trans>{feature.title}</Trans>
        </STitle>
        <AnimWrap key={`anim-${featureIndex}`} onAnimationEnd={handleAnimWrapFadedIn}>
          <FeaturesHintAnimation key={`rive-${featureIndex}`} feature={feature} ref={animationRef} />
        </AnimWrap>
        <SButton
          key={`button-${featureIndex}`}
          onClick={handleNextClick}
          variant="contained"
          size="large"
          disableElevation
        >
          <Trans>Continue</Trans>
        </SButton>
      </ModalContent>
    </DialogFullWindow>
  );
};

export default FeaturesHint;
