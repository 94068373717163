import { Quest } from "../Quest";
import ShareAndChrome from "./ShareAndChrome";

export function ShareAndChromeQuest() {
  return (
    <Quest questName="ShareAndChromeQuest" requiredExps={1}>
      <ShareAndChrome />
    </Quest>
  );
}
