import { SnackbarDetail } from "components/initializers/SnackbarProvider";
import React from "react";
import { CardEditDialogDetail } from "../components/cardEditDialog/CardEditDialog";
import { CardListOpenDetail } from "../root/main/components/CardList/CardList";
import { RecordingResultDetail, RecordingStartDetail } from "../components/speech/AudioInput";
import { AssistantOpenSettings } from "../components/assistant/AssistantPopup";
import { ReportIssueOpenSettings } from "../components/dialogs/ReportIssueDialog";
import { LogUserActionDetail } from "../components/initializers/UserActionsLogger";

export enum EVENT {
  expandDailyQuest = "expandDailyQuest",
  openFireStats = "openFireStats",
  snackbar = "snackbar",
  openNextItemDialog = "openNextItemDialog",
  updateMain = "updateMain",
  forcePetting = "forcePetting",
  refetchLibrary = "refetchLibrary",
  openCardEditDialog = "openCardEditDialog",
  testMeFromPickedCards = "testMeFromPickedCards",
  openGoalDialog = "openGoalDialog",
  openLoaderGlobalWithMemoDialog = "openLoaderGlobalWithMemoDialog",
  closeLoaderGlobalWithMemoDialog = "closeLoaderGlobalWithMemoDialog",
  openCardList = "openCardList",
  animateBackButton = "animateBackButton",
  openAssistant = "openAssistant",
  consentCheck = "consentCheck",
  ATTConsent = "ATTConsent",
  startRecording = "startRecording",
  stopRecording = "stopRecording",
  recordingStopped = "recordingStopped",
  recordingResult = "recordingResult",
  assistantClosed = "assistantClosed",
  resetAssistant = "resetAssistant",
  openReportIssueDialog = "openReportIssueDialog",
  refreshNotifications = "refreshNotifications",
  logUserAction = "logUserAction",
  openOwnWordDialog = "openOwnWordDialog"
}

type DispatchEvent =
  // events witout data
  ((
    event:
      | EVENT.expandDailyQuest
      | EVENT.openFireStats
      | EVENT.openNextItemDialog
      | EVENT.updateMain
      | EVENT.forcePetting
      | EVENT.refetchLibrary
      | EVENT.testMeFromPickedCards
      | EVENT.openGoalDialog
      | EVENT.animateBackButton
      | EVENT.assistantClosed
      | EVENT.resetAssistant
      | EVENT.consentCheck
      | EVENT.ATTConsent
      | EVENT.stopRecording
      | EVENT.recordingStopped
      | EVENT.refreshNotifications
      | EVENT.openLoaderGlobalWithMemoDialog
      | EVENT.closeLoaderGlobalWithMemoDialog
      | EVENT.openOwnWordDialog
  ) => void) &
    // events with data
    ((event: EVENT.snackbar, detail: SnackbarDetail) => void) &
    ((event: EVENT.openCardEditDialog, detail: CardEditDialogDetail) => void) &
    ((event: EVENT.startRecording, detail: RecordingStartDetail) => void) &
    ((event: EVENT.recordingResult, detail: RecordingResultDetail) => void) &
    ((event: EVENT.openAssistant, detail: AssistantOpenSettings) => void) &
    ((event: EVENT.openCardList, detail: CardListOpenDetail) => void) &
    ((event: EVENT.openReportIssueDialog, detail: ReportIssueOpenSettings) => void) &
    ((event: EVENT.logUserAction, detail: LogUserActionDetail) => void);

export const dispatchEvent: DispatchEvent = (event: EVENT, detail?: any) => {
  window.dispatchEvent(new CustomEvent(event, { detail }));
  return;
};

// shorthand for snackbars
export const snackbar = (text: React.ReactNode, detail?: Omit<SnackbarDetail, "text">) => {
  dispatchEvent(EVENT.snackbar, { ...detail, text });
};
