import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import { useSubscriptions } from "./subscriptionHooks";
import React from "react";

type Props = { overrideManage?: () => void };

export const ManageSubscriptionButton: React.FC<Props> = ({ overrideManage }) => {
  const { store } = useSubscriptions();

  const handleClick = () => {
    if (overrideManage) {
      overrideManage();
    } else {
      store.manageSubscriptions();
    }
  };

  return (
    <Button color="primary" onClick={handleClick}>
      <Trans>Manage Subscriptions</Trans>
    </Button>
  );
};
