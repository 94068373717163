import { BackButton, HeaderLayout } from "components/header/Header";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { FIVEHINT } from "root/main/main/Main";
import styled from "styled-components/macro";
import SourceDots from "./SourceDots";
import { devicePaddings } from "tools/device";
import { sourceQuery$data } from "queries/sources/__generated__/sourceQuery.graphql";
import Fire from "components/fire/Fire";
import { useLocation, useNavigate } from "react-router";
import { LISTHASH } from "../library/libraryUrls";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLibraryUrl } from "root/RootRouter";
import { useOpenCourseView } from "./course/courseHooks";
import useLangNative from "hooks/useLangNative";
import { useGetActiveTheoryTitle } from "components/Theory/theoryTools";
import { flag } from "tools/langs";
import Article from "./Article";
import SetCards from "./SetCards";
import Metadata from "./Metadata";
import Picker from "components/picker/Picker";
import { faGraduationCap } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { bottom } = devicePaddings();

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const PickerWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-bottom: ${bottom + 20}px;
`;
const TheoryDrop = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0;
  align-items: center;
  cursor: pointer;
`;
const TheoryDropIcon = styled.div`
  flex-shrink: 1;
  position: relative;
  top: 2px;
  margin-right: 5px;
  svg {
    color: ${({ theme }) => theme.duo.color.lightBlue};
    width: 24px;
    height: 24px;
  }
`;
const TheoryDropArrow = styled.div`
  flex-shrink: 1;
  min-width: 30px;
  display: flex;
  align-items: center;
`;

type Props = { source: NonNullable<sourceQuery$data["sourceByUriOrId"]> };

const SetAndArticleWrap: React.FC<Props> = ({ source }) => {
  const { hintWasSeen } = useViewerQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const listHash = location.hash === LISTHASH;
  const [showPickerRaw, setShowPicker] = React.useState<boolean | undefined>(listHash ? false : undefined);
  const { deck } = useViewerDecks();
  const libraryUrl = useLibraryUrl();
  const openCourseView = useOpenCourseView();
  const langNative = useLangNative();
  const theoryTitle = useGetActiveTheoryTitle(source);

  const showPicker = (source?.kind === "set" && showPickerRaw === undefined && !!deck) || !!showPickerRaw;

  const toggleShowPicker = () => {
    listHash && navigate({ hash: "" }, { replace: true });
    setShowPicker(!showPicker);
  };

  const doOpenCourseView = () => {
    source && openCourseView(source.uri, langNative, source.lang);
  };

  return (
    <HeaderLayout
      transparent={!showPicker}
      withShadow
      narrow
      right={
        hintWasSeen(FIVEHINT) && (
          <>
            <SourceDots source={source} onSetShowPicker={toggleShowPicker} showPicker={showPicker} />
            <div className="like-svg">
              <Fire />
            </div>
          </>
        )
      }
      left={<BackButton onBack={() => navigate(libraryUrl, { replace: true })} />}
      title={
        showPicker &&
        (source.course ? (
          <TheoryDrop onClick={doOpenCourseView}>
            <TheoryDropIcon>
              <FontAwesomeIcon icon={faGraduationCap} />
            </TheoryDropIcon>
            <Ellipsis>{theoryTitle}</Ellipsis>
            <TheoryDropArrow>
              <ArrowDropDownIcon />
            </TheoryDropArrow>
          </TheoryDrop>
        ) : (
          <Ellipsis>
            {flag(source.lang)} &nbsp;{source.name}
          </Ellipsis>
        ))
      }
    >
      {!showPicker ? (
        <>
          <Article source={source} />
          <SetCards source={source} onSetShowPicker={toggleShowPicker} />
        </>
      ) : (
        <>
          <PickerWrap>
            <Metadata source={source} />
            <Picker source={source} />
          </PickerWrap>
        </>
      )}
    </HeaderLayout>
  );
};

export default SetAndArticleWrap;
