import React, { useState } from "react";
import { ReassuranceSlide } from "./ReassuranceSlide";
import { Trans } from "react-i18next";
import { Box, Button, Stack } from "@mui/material";
import { BrandTitle } from "../../../components/other/BrandTitle";
import LoginHeader from "../LoginHeader";
import { Content, Page, IndicatorButton, Indicator } from "./ReassuranceScreen.styles";
import LoginGuest from "../LoginGuest";
import { Carousel } from "react-responsive-carousel";

type Props = {
  onLogin: () => void;
};

export function ReassuranceScreen(props: Props) {
  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
  const [autoPlay, setAutoPlay] = useState(true);

  return (
    <Page>
      <Content>
        <LoginHeader />
        <Box sx={{ textAlign: "center" }}>
          <BrandTitle>DuoCards</BrandTitle>
        </Box>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={autoPlay}
          infiniteLoop
          onSwipeMove={() => {
            setAutoPlay(false);
            return true;
          }}
          renderItem={customRenderItem}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return (
              <Indicator
                onClick={isSelected ? undefined : onClickHandler}
                onKeyDown={isSelected ? undefined : onClickHandler}
                key={index}
                role="button"
                tabIndex={0}
                title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
              >
                <IndicatorButton $active={isSelected} />
              </Indicator>
            );
          }}
        >
          <ReassuranceSlide
            key="1"
            url="https://imgs.duocards.com/rive/reassurance-slide-1.riv"
            legend={
              <Trans>
                Learn any language easily, just like our community of <strong>5 million students</strong>!
              </Trans>
            }
          />
          <ReassuranceSlide
            key="2"
            url="https://imgs.duocards.com/rive/reassurance-slide-2.riv"
            legend={
              <Trans>
                <strong>Add words, phrases and sentences</strong> from anywhere
              </Trans>
            }
          />
          <ReassuranceSlide
            key="3"
            url="https://imgs.duocards.com/rive/reassurance-slide-3.riv"
            legend={
              <Trans>
                Swipe and let the <strong>algorithm do the rest</strong>
              </Trans>
            }
          />
          <ReassuranceSlide
            key="4"
            url="https://imgs.duocards.com/rive/reassurance-slide-4.riv"
            legend={
              <Trans>
                Practise with <strong>Memo, our AI assistant</strong>
              </Trans>
            }
          />
        </Carousel>
        <Box sx={{ textAlign: "center", paddingLeft: 3, paddingRight: 3 }}>
          <Stack direction="column" spacing={2}>
            <Button variant="text" color="inherit" fullWidth onClick={props.onLogin}>
              <Trans>I already have an account</Trans>
            </Button>
            <LoginGuest />
          </Stack>
        </Box>
      </Content>
    </Page>
  );
}
