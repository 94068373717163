import { queryString } from "tools/url";
import { Kind } from "../../../queries/sources/__generated__/sourceQuery.graphql";

import { LIBRARY } from "../../RootRouter.config";

export const LISTHASH = `#list`;
export const LANGNATIVEPARAM = "ln";

export const SOURCE = {
  relative: `s/:uri`,
  // list is not used yet
  url: (uri: string, langNative?: string, opts?: { list?: boolean }) =>
    `${LIBRARY.root()}/s/${uri}${langNative ? `?${LANGNATIVEPARAM}=${langNative}` : ``}${opts?.list ? LISTHASH : ``}`,
  query: () => `${LIBRARY.root()}/${SOURCE.relative}`
};

export const SOURCENEW = {
  relative: "new",
  url: () => `${LIBRARY.root()}/${SOURCENEW.relative}`
};

export const SOURCEFORM = {
  relative: "edit",
  query: () => `${LIBRARY.root()}/${SOURCEFORM.relative}`,
  url: (params?: { id?: string; action?: "cards"; kind?: "set" | "article" }) =>
    SOURCEFORM.query() + queryString(params),
  idParam: "id",
  kindParam: "kind",
  actionParam: "action"
};

export const VIDEOFORM = {
  relative: "editvideo",
  query: () => `${LIBRARY.root()}/${VIDEOFORM.relative}`,
  url: (params?: { id?: string; term?: string }) => VIDEOFORM.query() + queryString(params),
  idParam: "id",
  termParam: "term"
};

export const MYSOURCES = {
  relative: "mine",
  url: () => `${LIBRARY.root()}/${MYSOURCES.relative}`
};

export const getSourceEditUrl = (source: { kind: Kind; id: string }, action?: "cards") => {
  if (source.kind === "video") {
    return VIDEOFORM.url({ id: source.id });
  } else {
    return SOURCEFORM.url({ id: source.id, action });
  }
};
