import React from "react";
import { useMutation } from "relay-hooks";
import { CreateDeck } from "../../../queries/decks/createDeck";
import { useViewerDecks } from "queries/viewerQuery";
import DeckForm, { Values } from "../../main/deck/DeckForm";
import { mutation as deleteDeckMutation } from "queries/decks/deleteDeck";
import { useDBLog } from "hooks/useDBLog";

type LanguageSelectStep = "language-select-1" | "language-select-2";

const useCreateDeck = () => {
  const [createDeck] = useMutation(CreateDeck);
  return (values: Values) =>
    createDeck({ variables: { front: values.front, back: values.back } })
      .then(() => true)
      .catch(() => false);
};

interface LanguagesSelectProps {
  onCanSubmitChange: (canSubmit: boolean, submitFunction?: () => Promise<boolean>) => void;
  step: LanguageSelectStep;
}

const LanguagesSelect: React.FC<LanguagesSelectProps> = ({ onCanSubmitChange, step }) => {
  const { decks } = useViewerDecks();
  const createDeck = useCreateDeck();
  const [deleteDeck] = useMutation(deleteDeckMutation);
  const dbLog = useDBLog();

  const handleCanSubmitChange = (canSubmit: boolean, values: Values) => {
    if (step === "language-select-2") {
      onCanSubmitChange(canSubmit, () => createDeck(values));
    } else {
      onCanSubmitChange(canSubmit);
    }
  };

  React.useEffect(() => {
    // if user get back to language-select-1, delete the deck, and the form will reset
    if (step === "language-select-1" && decks?.length && decks[0].id) {
      if (decks[0].stats.total === 0) deleteDeck({ variables: { id: decks[0].id } });
      else dbLog("delete_deck", "deleting deck", { total: decks[0].stats.total, id: decks[0].id }); // should not happen
    }
  }, [decks, step, deleteDeck, dbLog]);

  React.useEffect(() => {
    if (step === "language-select-2" && decks?.length && decks[0].id) {
      onCanSubmitChange(true);
    }
  }, [decks, step, onCanSubmitChange]);

  return (
    <DeckForm
      inOnboarding={true}
      isStep1Done={step === "language-select-2"}
      onCanSubmitChange={handleCanSubmitChange}
      editId={decks?.[0]?.id}
    />
  );
};

export default LanguagesSelect;
