import React from "react";
import styled from "styled-components/macro";
import { dispatchEvent, EVENT } from "tools/events";
import { GardenXps } from "../Garden";
import Cloud from "./Cloud";
import Sun from "./Sun";

const Wrap = styled.div`
  width: 55px;
  height: 55px;
  position: absolute;
  left: 289px;
  top: -13px;
  z-index: 5;
  cursor: pointer;
`;
export const Streak = styled.div<{ withCloud: boolean }>`
  font-weight: bold;
  color: white;
  font-size: 14px;
  line-height: 1.3em;
  text-align: center;
  margin-top: ${({ withCloud }) => (withCloud ? 24 : 19)}px;
  position: relative;
  z-index: 10;
  text-shadow: 0 0 2px #333;
`;

type Props = {
  xps: GardenXps;
};

const SunStreak: React.FC<Props> = ({ xps }) => {
  const withCloud = !xps.currentStreak;

  return (
    <Wrap onClick={() => dispatchEvent(EVENT.openFireStats)}>
      <Sun />
      {withCloud && <Cloud />}
      <Streak withCloud={withCloud}>{xps.currentStreak}</Streak>
    </Wrap>
  );
};

export default SunStreak;
