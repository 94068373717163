import { Paper } from "@mui/material";
import FlatImg from "components/other/FlatImg";
import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { genderStyle } from "tools/genderStyle";
import { useBurn } from "../../../components/fire/xpHooks";
import { loopQuery_loop$data } from "../../../queries/decks/__generated__/loopQuery_loop.graphql";
import { useTtsQuery } from "../../../queries/ttsQuery";
import { useViewerDecks } from "../../../queries/viewerQuery";
import { devicePaddings } from "../../../tools/device";
import CardSettings from "../components/CardSettings";
import Pronunciation from "../components/Pronunciation";
import UpSize from "../components/UpSize";
import { useGetState } from "components/ReduxProvider";

const COMPLETED_MS = 1200;

const { top, bottom } = devicePaddings();

const Wrap = styled.div<{ completed: boolean; behind: boolean }>`
  position: absolute;
  width: 100%;
  height: calc(100% - ${56 + top + bottom}px);
  display: flex;
  margin-top: ${({ behind }) => (behind ? -20 : 0)}px;
  padding: 10px;
  z-index: ${({ behind }) => (behind ? 11 : 650)}; /* 11:behind first card, 650:above card bottom */
  background-color: ${({ theme, behind }) => (behind ? "ffffffbb" : `${theme.duo.color.lightGrey}bb`)};
  transform: ${({ behind }) => (behind ? "scale(0.75)" : "scale(1)")};
  backdrop-filter: blur(8px);
  opacity: ${({ completed }) => (completed ? 0 : 1)};
  transition:
    all 200ms,
    opacity ${COMPLETED_MS}ms cubic-bezier(0.9, 0, 1, 1);
  pointer-events: ${({ completed }) => (completed ? "none" : "auto")};
`;
const Column = styled.div`
  width: 50%;
`;
const redKf = keyframes`
  0%, 100% { border-color: #ddd }
  40%, 40% { border-color: red }
`;
const Card = styled(Paper)<{ $right?: boolean; $clicked?: boolean; $wrong?: boolean }>`
  margin: 0 5px 10px;
  padding: 0 10px;
  height: calc(18.6% - 10px);
  line-height: 1.35em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s;
  border: 2px solid #ddd;
  border-color: ${({ $clicked, theme, $right }) =>
    $clicked ? `${theme.duo.color.primary}88` : $right ? theme.duo.color.green : "#ddd"};
  pointer-events: ${({ $right }) => ($right ? "none" : "auto")};
  border-radius: 9px;
  overflow: hidden;
  &,
  & span {
    color: ${({ $right, theme, $clicked }) =>
      $clicked ? theme.duo.color.primary : $right ? theme.duo.color.green : ""};
  }
  ${({ $wrong }) =>
    $wrong &&
    css`
      animation: ${redKf} 1.2s normal;
    `}
`;
const Small = styled.div`
  font-size: 80%;
  line-height: 1.4em;
`;
const SUpSize = styled(UpSize)`
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`;
const In = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

type Props = {
  behind: boolean;
  cards: { front: loopQuery_loop$data["loop"]; back: loopQuery_loop$data["loop"] };
  onIsPrepared: (val: boolean) => void;
};

const PairsComp: React.FC<Props> = ({ behind, cards, onIsPrepared }) => {
  const { deck } = useViewerDecks();
  const tts = useTtsQuery(deck?.front);
  const [clicked, setClicked] = React.useState<{ id: string; side: string }>();
  const [wrongs, setWrongs] = React.useState<{ id: string; side: string }[]>();
  const [paired, setPaired] = React.useState<string[]>([]);
  const burn = useBurn();
  const voiceOff = useGetState("voiceOff");

  const handleClick = (card: { id: string; front: string }, side: string) => {
    const { id, front: text } = card;
    if (side === "front") {
      tts && !voiceOff && tts({ text });
    }
    if (!clicked || (clicked.side === side && clicked.id !== id)) {
      setClicked({ id, side });
      setWrongs(undefined);
    } else {
      if (clicked.id === id) {
        if (clicked.side !== side) {
          paired.length % 2 && burn();
          setPaired([...paired, id]);
          if (cards.front.length === paired.length + 1) {
            setTimeout(() => onIsPrepared(false), COMPLETED_MS * 1.5);
          }
        }
      } else {
        setWrongs([clicked, { id, side }]);
      }
      setClicked(undefined);
    }
  };

  return (
    <Wrap completed={cards.front.length === paired.length} behind={behind}>
      {["back", "front"].map((side) => (
        <Column key={side}>
          {cards[side].map((card: loopQuery_loop$data["loop"][0]) => (
            <Card
              key={card.id}
              onClick={() => handleClick(card, side)}
              $right={paired.includes(card.id)}
              $clicked={clicked?.id === card.id && side === clicked?.side}
              $wrong={wrongs?.some((w) => w.id === card.id && w.side === side)}
            >
              <In>
                {side === "back" && card.svg ? (
                  <>
                    <FlatImg img={card.svg} size={50} />
                    <Small>
                      <SUpSize lang={deck![side] as string}>{card[side]}</SUpSize>
                    </Small>
                  </>
                ) : (
                  <SUpSize lang={deck![side]}>
                    {side === "front" ? genderStyle(card[side], deck!.front) : card[side]}
                  </SUpSize>
                )}
                {side === "front" && deck && <Pronunciation lang={deck.front}>{card.pronunciation}</Pronunciation>}
              </In>
            </Card>
          ))}
        </Column>
      ))}
      {deck && !behind && <CardSettings style={{ bottom: 10, left: 10 }} lang={deck.front} />}
    </Wrap>
  );
};

export default PairsComp;
