import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Box } from "@mui/material";

const Item = styled(Box)`
  padding-bottom: 50px;
`;
const RiveWrap = styled(Box)`
  height: 180px;
`;

type Props = {
  url: string;
  legend: JSX.Element;
  isSelected?: boolean;
};

export const ReassuranceSlide = ({ url, isSelected, legend }: Props) => {
  const { rive, RiveComponent: RiveComponentPlayback } = useRive({
    src: url,
    autoplay: false,
    layout: new Layout({ fit: Fit.Contain, alignment: Alignment.TopCenter })
  });

  useEffect(() => {
    if (rive) {
      if (isSelected) {
        setTimeout(() => rive.play(), 300);
      } else {
        rive.stop();
      }
    }
  }, [isSelected, rive]);

  return (
    <Item>
      <RiveWrap>{isSelected && <RiveComponentPlayback />}</RiveWrap>
      <p>{legend}</p>
    </Item>
  );
};
