import React from "react";
import styled from "styled-components/macro";
import { loopQuery_loop$data } from "../../../queries/decks/__generated__/loopQuery_loop.graphql";
import { formatDistanceToNowStrict } from "date-fns";

const Wrap = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 10000;
  font-size: 0.8rem;
  line-height: 1.3rem;
`;

type Props = {
  card: loopQuery_loop$data["loop"][0];
};

const CardDebug: React.FC<Props> = ({ card }) => {
  let swipeRightTime: string = "";
  switch (card.knownCount) {
    case 0:
      swipeRightTime = "92 seconds";
      break;
    case 1:
      swipeRightTime = "30 minutes";
      break;
    case 2:
      swipeRightTime = "12 hours";
      break;
    case 3:
      swipeRightTime = "3 days";
      break;
    case 4:
      swipeRightTime = "2 weeks";
      break;
    case 5:
      swipeRightTime = "2 months";
      break;
    case 6:
      swipeRightTime = "never";
      break;
  }

  let swipeLeftTime: string = "";
  switch (card.knownCount) {
    case 0:
      swipeLeftTime = "92 seconds";
      break;
    case 1:
      swipeLeftTime = "30 minutes";
      break;
    case 2:
      swipeLeftTime = "12 hours";
      break;
    case 3:
      swipeLeftTime = "12 hours";
      break;
    case 4:
      swipeLeftTime = "3 days";
      break;
    case 5:
      swipeLeftTime = "3 days";
      break;
    case 6:
      swipeLeftTime = "2 weeks";
      break;
  }

  return (
    <Wrap>
      <div>knownCount: {card.knownCount}</div>
      <div>swipe -&gt;: {swipeRightTime}</div>
      <div>swipe &lt;- & -&gt;: {swipeLeftTime}</div>
      <div>failCount: {card.failCount}</div>
      <div>flipped: {card.flipped ? "yes" : "no"}</div>
      <div>knownAt: {card.knownAt ? formatDistanceToNowStrict(card.knownAt) + " ago" : "never"}</div>
      <div>loopedAt: {formatDistanceToNowStrict(card.loopedAt)} ago</div>
    </Wrap>
  );
};

export default CardDebug;
