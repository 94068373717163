/**
 * @generated SignedSource<<6a6542442cd637a4bf45e7ca8086747e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionPlatform = "android" | "browser" | "ios" | "lemon" | "%future added value";
export type InviteAcceptDialogCnfMutation$variables = {};
export type InviteAcceptDialogCnfMutation$data = {
  readonly confirmFriendhips: {
    readonly addedMonths: number;
    readonly newFriends: ReadonlyArray<{
      readonly friend: {
        readonly name: string | null;
      };
    }>;
    readonly viewer: {
      readonly subscriptions: ReadonlyArray<{
        readonly family: boolean;
        readonly platform: SubscriptionPlatform;
        readonly storeId: string;
        readonly timeToExpire: number;
        readonly transactionId: string;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_friends">;
    };
  };
};
export type InviteAcceptDialogCnfMutation = {
  response: InviteAcceptDialogCnfMutation$data;
  variables: InviteAcceptDialogCnfMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedMonths",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAcceptDialogCnfMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConfirmFriendshipsRes",
        "kind": "LinkedField",
        "name": "confirmFriendhips",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "viewerQuery_friends"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Friend",
            "kind": "LinkedField",
            "name": "newFriends",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "friend",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InviteAcceptDialogCnfMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConfirmFriendshipsRes",
        "kind": "LinkedField",
        "name": "confirmFriendhips",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Friends",
                "kind": "LinkedField",
                "name": "friends",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Friend",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "friend",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "picture",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Xps",
                            "kind": "LinkedField",
                            "name": "xps",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "today",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentStreak",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasOnlyFewFriends",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shouldBeConfirmed",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Friend",
            "kind": "LinkedField",
            "name": "newFriends",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "friend",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "205ffdf830e1d60895b20144c4974341",
    "id": null,
    "metadata": {},
    "name": "InviteAcceptDialogCnfMutation",
    "operationKind": "mutation",
    "text": "mutation InviteAcceptDialogCnfMutation {\n  confirmFriendhips {\n    viewer {\n      ...viewerQuery_friends\n      subscriptions {\n        transactionId\n        timeToExpire\n        platform\n        storeId\n        family\n      }\n      id\n    }\n    newFriends {\n      friend {\n        name\n        id\n      }\n    }\n    addedMonths\n  }\n}\n\nfragment viewerQuery_friends on User {\n  friends {\n    edges {\n      friend {\n        id\n        name\n        picture\n        xps {\n          total\n          today\n          currentStreak\n        }\n      }\n      hasOnlyFewFriends\n      shouldBeConfirmed\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a2e71d0b68877d5aece0555574db73a";

export default node;
