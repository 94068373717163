import { StoreId, useStoreIds } from "./subscriptionIds";
import { useViewerQuery, Viewer } from "queries/viewerQuery";
import { SubType } from "components/ReduxProvider";
import { findTimezonePriceData, formatPrice, isSubWithTrial, TimezonePrice } from "sharedJs__generated/functions";
import { useLemonLink } from "./LemonLink";

export const useLemonStore = () => {
  const { storeIdFamily, storeIdMonth, storeIdYear } = useStoreIds();
  const { viewer } = useViewerQuery();
  const { redirectToLemon } = useLemonLink();

  const browserSubscriptions = createBrowserSubscriptions(viewer, storeIdMonth, storeIdYear, storeIdFamily);
  const browserStore = createBrowserStore(redirectToLemon);

  return { subscriptions: browserSubscriptions, store: browserStore };
};

export const formatPriceWithCurrency = (amount?: number | string, currency?: string): string => {
  if (!currency) return `${amount}`;

  let prefix = "";
  if (currency === "USD") prefix = "$";
  else if (currency === "EUR") prefix = "€";

  return `${prefix}${amount} ${currency}`;
};

const createBrowserSubscriptions = (
  viewer: Viewer | undefined,
  storeIdMonth: StoreId,
  storeIdYear: StoreId,
  storeIdFamily: StoreId
): Record<string, SubType> => {
  if (!viewer?.timezonePrices || !Array.isArray(viewer.timezonePrices)) return {};
  const monthPriceData = findTimezonePriceData(storeIdMonth, viewer.timezonePrices);
  const yearPriceData = findTimezonePriceData(storeIdYear, viewer.timezonePrices);
  const familyPriceData = findTimezonePriceData(storeIdFamily, viewer.timezonePrices);

  return {
    [storeIdMonth]: createSubscription(monthPriceData, storeIdMonth),
    [storeIdYear]: createSubscription(yearPriceData, storeIdYear),
    [storeIdFamily]: createSubscription(familyPriceData, storeIdFamily)
  };
};

const createSubscription = (priceData: TimezonePrice | undefined | null, storeId: StoreId): SubType => ({
  canPurchase: true,
  price: formatPriceWithCurrency(formatPrice(priceData?.amount ?? 0), priceData?.currency),
  priceMicros: formatPrice(priceData?.amount ?? 0) * 1_000_000,
  priceOrig: formatPriceWithCurrency(priceData?.amountOriginal, priceData?.currencyOriginal),
  storeId,
  currency: priceData?.currency ?? "",
  currencyOrig: priceData?.currencyOriginal,
  isTrial: isSubWithTrial(storeId)
});

const createBrowserStore = (redirectToLemon: (options: { variables: any }) => void) => ({
  manageSubscriptions: () => redirectToLemon({ variables: { type: "portal" } }),
  getLemonCheckoutLink: (storeId: string) =>
    redirectToLemon({ variables: { type: "checkout", storeId, returnPath: window.location.pathname } }),
  order: () => {},
  update: () => {},
  initialize: () => {},
  restorePurchases: () => {},
  register: () => {},
  when: () => ({ updated: () => ({ approved: () => ({}) }) }),
  error: () => {},
  localReceipts: [],
  get: () => ({ getOffer: () => ({ order: () => {} }) }),
  PAID_SUBSCRIPTION: "PAID_SUBSCRIPTION"
});
