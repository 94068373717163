import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useNavigate } from "react-router";
import { deleteDeckMutation } from "./__generated__/deleteDeckMutation.graphql";
import { useViewerDecks } from "../viewerQuery";
import { useTranslation } from "react-i18next";
import { MAIN } from "../../root/RootRouter.config";

export const mutation = graphql`
  mutation deleteDeckMutation($id: ID!) {
    deleteDeck(id: $id) {
      ...viewerQuery_decks
    }
  }
`;

export const useDeleteDeckMutation = () => {
  const navigate = useNavigate();
  const { decks } = useViewerDecks();
  const { t } = useTranslation();

  const [mutate] = useMutation<deleteDeckMutation>(mutation, {
    onCompleted: () => navigate(`${MAIN.url()}`)
  });

  return (variables: deleteDeckMutation["variables"]) => {
    const deck = decks?.find((d) => d.id === variables.id);
    if (!deck?.stats.total || window.confirm(t("Really delete?"))) {
      mutate({ variables });
    }
  };
};
