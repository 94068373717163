import { useViewerQuery } from "../../../queries/viewerQuery";
import { isAndroid, isBrowser } from "../../../tools/device";

export const StoreName = () => {
  const { viewer } = useViewerQuery();
  const platform = viewer?.subscription?.platform;
  const android = platform === "android" || (!platform && isAndroid());
  const lemon = isBrowser();

  return <>{android ? "Google Play Store" : lemon ? "Lemon Squeezy, LLC" : "App Store"}</>;
};
