import React from "react";
import { Trans } from "react-i18next";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { useSeeHint } from "components/hint/Hint";
import HelpFindCave, { SHOW_SOURCES_QUEST } from "./HelpFindCave";
import QuestsExplained from "./QuestsExplained";
import QuestReward from "../QuestReward";
import { dispatchEvent, EVENT } from "tools/events";
import OwnWordAddButton from "./OwnWordAddButton";
import { QuestMessage } from "../misc/QuestMessage";
import { useEffectOnce } from "react-use";

export const OWNWORDQUEST = "ownWord";

const OwnWord: React.FC = () => {
  const { hintWasSeen, getFlag } = useViewerQuery();
  const questsExplained = useGetState("questsExplained");
  const setQuestsExplained = useSetState("questsExplained");
  const ownWordAdded = useGetState("ownWordAdded");
  const seeHint = useSeeHint();
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");

  const abtest_lessInfantil = getFlag("abtest_lessInfantil")?.value === "on";

  React.useEffect(() => {
    setShowQuestsBackdrop(true);
    dispatchEvent(EVENT.updateMain);
  }, [questsExplained, setShowQuestsBackdrop, ownWordAdded]);

  useEffectOnce(() => {
    if (abtest_lessInfantil) {
      // skips both steps <HelpFindCave /> and <QuestsExplained />
      seeHint(SHOW_SOURCES_QUEST);
      setQuestsExplained(true);
    }
  });

  return (
    <QuestMessage>
      {!hintWasSeen(SHOW_SOURCES_QUEST) ? (
        <HelpFindCave />
      ) : !questsExplained ? (
        <QuestsExplained />
      ) : (
        <div>
          {abtest_lessInfantil && (
            <p
              style={{
                margin: "5px 0",
                fontSize: "85%",
                lineHeight: "1.5em",
                textDecoration: ownWordAdded ? "line-through" : "none"
              }}
            >
              <Trans>One more important feature to discover:</Trans>
            </p>
          )}
          <p style={{ margin: "5px 0", textDecoration: ownWordAdded ? "line-through" : "none" }}>
            <Trans>Create at least one word on your own.</Trans>
          </p>

          {!ownWordAdded && <OwnWordAddButton />}

          <QuestReward
            rewardedXps={10}
            claim={ownWordAdded}
            onDone={() => {
              setTimeout(() => seeHint(OWNWORDQUEST), 2000);
              setTimeout(() => dispatchEvent(EVENT.updateMain), 3000);
            }}
          />
        </div>
      )}
    </QuestMessage>
  );
};

export const useCreatingNewCard = () => {
  const { hintWasSeen } = useViewerQuery();
  const ownWordAdded = useGetState("ownWordAdded");

  return hintWasSeen(SHOW_SOURCES_QUEST) && !hintWasSeen(OWNWORDQUEST) && !ownWordAdded;
};

export default OwnWord;
