export enum PROXY {
  retained_1_hour = `retained_1_hour`,
  active_after_onboarding = `active_after_onboarding`,
  user_liked_app = `user_liked_app`,
  social_popup_displayed = `social_popup_displayed`
}

/**
  Cordova plugins docs:
    https://github.com/cordova-plugin-facebook-connect/cordova-plugin-facebook-connect
    https://github.com/chemerisuk/cordova-plugin-firebase-analytics
    https://support.singular.net/hc/en-us/articles/8586543222683-Singular-SDK-Integration-for-Cordova

  debugging:
    https://www.facebook.com/events_manager2/list/app/2599670450282809/overview?act=401611451583239
    https://console.firebase.google.com/u/0/project/duocards/analytics/app/ios:com.duocards.app/events
    https://app.singular.net/#/react/testing-console
*/

export const logEvent = (eventName: string) => {
  (window as any).facebookConnectPlugin?.logEvent(eventName);
  window.cordova?.plugins?.firebase?.analytics?.logEvent(eventName);
  // window.plugins?.appsFlyer?.logEvent(eventName, {}, null, null);
  window.cordova?.plugins?.SingularCordovaSdk?.event(eventName);
};

export const logFBTutorialCompletedEvent = () => {
  (window as any).facebookConnectPlugin?.logEvent("fb_mobile_tutorial_completion", {
    fb_success: 1,
    fb_content: "duocards"
  });
};

type Params = { price: number; currency: string };
export const logPurchase = async ({ price, currency }: Params) => {
  if (process.env.NODE_ENV === "development") return;

  (window as any).facebookConnectPlugin?.logPurchase(price, currency);

  window.cordova?.plugins?.firebase?.analytics?.logEvent("purchase_estimate", { value: price, currency });

  //window.plugins?.appsFlyer?.logEvent( "af_subscribe",{ af_revenue: price, af_currency: currency, af_subscription_id: subscriptionId }, null, null );

  window.cordova?.plugins?.SingularCordovaSdk?.customRevenue("purchase", currency, price);
};
