import React from "react";
import styled from "styled-components/macro";
import { Fade } from "@mui/material";
import { useEvent } from "react-use";
import { EVENT } from "../../tools/events";
import Rive from "@rive-app/react-canvas";
import { Trans } from "react-i18next";
import DialogFullWindow from "../other/DialogFullWindow";
const Wrap = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 25px 30px;
  align-items: center;
`;

const SDialogFullWindow = styled(DialogFullWindow)`
  background-color: ${({ theme }) => theme.duo.color.lightGrey};
`;

type Props = {};

const LoaderGlobalWithMemoDialog: React.FC<Props> = () => {
  const [open, setOpen] = React.useState(false);

  const openFn = React.useCallback(() => setOpen(true), []);
  const closeFn = React.useCallback(() => setOpen(false), []);

  useEvent(EVENT.openLoaderGlobalWithMemoDialog, openFn);
  useEvent(EVENT.closeLoaderGlobalWithMemoDialog, closeFn);

  return (
    <SDialogFullWindow open={open} TransitionComponent={Fade}>
      <Wrap>
        <Rive src="https://storage.googleapis.com/imgs.duocards.com/rive/loading.riv" stateMachines={["Anim"]} />

        <h1>
          <Trans>Please wait...</Trans>
        </h1>
      </Wrap>
    </SDialogFullWindow>
  );
};

export default LoaderGlobalWithMemoDialog;
