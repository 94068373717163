import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { FIVEHINT } from "root/main/main/Main";
import { useLocation, useMatch } from "react-router";
import { useMatchMain } from "root/RootRouter";
import { FIRSTFEWHINT } from "./steps/FirstFewCards";
import { useSeeHint } from "components/hint/Hint";
import { LIBRARY, ONBOARDING, MAIN } from "../RootRouter.config";
import { StepScreenId, getNearestNonVirtualStep } from "./onboarding/onboardingStepsData";
import { getStepHash } from "./onboarding/OnboardingRedirect";
import useOnboardingData, { PreparedSources } from "hooks/useOnboardingData";
import { useNavigate } from "react-router-dom";
import { FIRSTCARDSCOUNT } from "./Tutorial";
import { useDBLog } from "hooks/useDBLog";
import { TUTORIALDONE } from "./steps/Verdict";
import { PETMAMMOTH } from "./steps/PetMammoth";

const getScreenIdForPreparedSources = (preparedSources: PreparedSources): StepScreenId => {
  if (preparedSources.videoId) {
    return StepScreenId.PREPARED_VIDEO;
  }
  if (preparedSources.articleId) {
    return StepScreenId.PREPARED_ARTICLE;
  }
  return StepScreenId.START_LEARNING; // no article or video -> start learning
};

const shouldBeInMainScreen = (nextStep: StepScreenId, hintWasSeen: (hint: string) => boolean): boolean => {
  const mainScreenSteps = [
    StepScreenId.PREPARED_COURSE_OPENED,
    StepScreenId.PREPARED_VIDEO_OPENED,
    StepScreenId.PREPARED_ARTICLE_OPENED,
    StepScreenId.PREPARED_COURSE,
    StepScreenId.PREPARED_VIDEO,
    StepScreenId.PREPARED_ARTICLE,
    StepScreenId.START_LEARNING
  ];

  return hintWasSeen(FIVEHINT) && mainScreenSteps.includes(nextStep);
};

export const useOnboardingInitialAndForceRedirect = (shouldSkipOnboarding: boolean) => {
  const { deck } = useViewerDecks();
  const seeHint = useSeeHint();
  const [initialRedirectDone, setInitialRedirectDone] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { getOnboardingData } = useOnboardingData();
  const { hintWasSeen, viewer } = useViewerQuery();
  const firstLoad = React.useRef(true);
  const onboardingData = getOnboardingData();
  const inMain = useMatchMain({ end: true });
  const inOnboarding = useMatch(ONBOARDING.url());
  const inLibraryRoot = useMatch(`${LIBRARY.root()}/:langcode`);
  const dbLog = useDBLog();

  React.useEffect(() => {
    if (shouldSkipOnboarding) {
      setInitialRedirectDone(true);
      return;
    }
    if (firstLoad.current || ((location.pathname === "/" || inMain || inOnboarding) && !location.hash)) {
      firstLoad.current = false;
      const { currentStep, latestStepReached, preparedSources } = getOnboardingData();

      let nextStep: StepScreenId | undefined;
      if (currentStep) {
        nextStep = currentStep;
      } else if (!latestStepReached) {
        nextStep = StepScreenId.WELCOME;
      } else if (!hintWasSeen(FIRSTFEWHINT)) {
        nextStep = StepScreenId.PREPARED_COURSE;
      } else if (!hintWasSeen(FIVEHINT)) {
        nextStep = getScreenIdForPreparedSources(preparedSources);
      } else {
        nextStep = latestStepReached;
      }

      nextStep = getNearestNonVirtualStep(nextStep);

      if (nextStep) {
        const targetUrl = shouldBeInMainScreen(nextStep, hintWasSeen)
          ? MAIN.url()
          : ONBOARDING.url() + getStepHash(nextStep);

        if (location.pathname !== targetUrl) {
          if (deck?.stats.total && deck.stats.total > FIRSTCARDSCOUNT) {
            dbLog("redirecting onboarding", "redirect 1", {
              location: location.pathname,
              target: targetUrl,
              seenHints: hintWasSeen(TUTORIALDONE),
              deckid: deck?.id,
              decktotal: deck?.stats.total,
              createdBeforeDays: viewer?.createdBeforeDays
            });
          }
          navigate(targetUrl);
        }
      }
      setInitialRedirectDone(true);
    }
  }, [
    shouldSkipOnboarding,
    navigate,
    getOnboardingData,
    location,
    hintWasSeen,
    inLibraryRoot,
    inMain,
    inOnboarding,
    dbLog,
    deck,
    viewer
  ]);

  React.useEffect(() => {
    if (shouldSkipOnboarding) return;

    if (deck?.stats.total === FIRSTCARDSCOUNT && !hintWasSeen(FIRSTFEWHINT)) {
      seeHint(FIRSTFEWHINT);
      const screenId = getScreenIdForPreparedSources(onboardingData.preparedSources);
      navigate(ONBOARDING.url() + getStepHash(screenId), { replace: true });

      if (viewer?.createdBeforeDays && viewer.createdBeforeDays > 1) {
        dbLog("redirecting onboarding", "redirect 2", {
          target: ONBOARDING.url() + getStepHash(screenId),
          seenHints: hintWasSeen(TUTORIALDONE),
          createdBeforeDays: viewer?.createdBeforeDays,
          deckid: deck?.id,
          decktotal: deck?.stats.total
        });
      }
    }
  }, [deck, hintWasSeen, seeHint, navigate, onboardingData.preparedSources, dbLog, viewer, shouldSkipOnboarding]);

  return initialRedirectDone;
};

export const useBlockOnboardingAfterCompletion = () => {
  const { hintWasSeen } = useViewerQuery();
  const hasOnboardingCompleted = hintWasSeen(PETMAMMOTH);
  const inOnboarding = useMatch(ONBOARDING.url());
  const navigate = useNavigate();

  React.useEffect(() => {
    if (hasOnboardingCompleted && inOnboarding) {
      navigate(MAIN.url(), { replace: true });
    }
  }, [hasOnboardingCompleted, inOnboarding, navigate]);
};
