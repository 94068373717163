import { Trans } from "react-i18next";
import { useSubscriptions } from "./subscriptionHooks";
import { StoreName } from "./StoreName";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { isBrowser } from "tools/device";
import { graphql } from "relay-runtime";
import { useMutation } from "relay-hooks";
import { SubscriptionFooterRevalidateMutation } from "./__generated__/SubscriptionFooterRevalidateMutation.graphql";

export const revalidateSubscriptionQL = graphql`
  mutation SubscriptionFooterRevalidateMutation {
    revalidateSubscription {
      subscriptions {
        transactionId
        timeToExpire
        platform
        storeId
        family
        famembers {
          id
          name
          picture
          xps {
            total
            today
            currentStreak
          }
        }
      }
    }
  }
`;

const Footer = styled.footer`
  color: #999;
  font-size: 11px;
  line-height: 1.35em;
  margin: 12px 0 8px;
`;

const Restore = styled(Button)`
  font-size: 12px;
  font-weight: normal;
  display: block;
  margin: 5px auto;
  color: grey;
`;

export const SubscriptionFooter = () => {
  const { store } = useSubscriptions();
  const { viewer } = useViewerQuery();
  const [clicked, setClicked] = React.useState(false);
  const [revalidateSubscription] = useMutation<SubscriptionFooterRevalidateMutation>(revalidateSubscriptionQL);

  const restore = () => {
    store.restorePurchases();
    revalidateSubscription({ variables: {} });
    setClicked(true);
  };

  return (
    <Footer>
      <Trans>Payment provider</Trans>: <StoreName />
      <br />
      <Trans>Subscription can be cancelled anytime after purchase and you still get your Premium period.</Trans>
      {!isBrowser() && !clicked && (store?.restorePurchases as any) && !viewer?.subscription && (
        <Trans parent={Restore} onClick={restore}>
          Restore purchase
        </Trans>
      )}
    </Footer>
  );
};
