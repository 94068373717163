import React from "react";
import styled from "styled-components/macro";
import { addSeconds, isFuture } from "date-fns";
import { getSubscriptionFlags, SubscriptionFlags } from "queries/users/userSubscriptionModel";
import { Viewer } from "../../../queries/viewerQuery";

const Green = styled.span`
  color: ${(p) => p.theme.duo.color.green};
`;
const Red = styled.span`
  color: ${(p) => p.theme.duo.color.red};
`;

type Props = {
  subscription?: {
    timeToExpire: number;
    storeId: string;
    family: boolean;
  } | null;
};

export const SubscriptionDisplay: React.FC<Props> = ({ subscription }) => {
  const subscriptionFlags: SubscriptionFlags = getSubscriptionFlags(subscription as Viewer["subscription"]);

  if (!subscription) return <>No subscription</>;

  const expiresAt = addSeconds(new Date(), subscription.timeToExpire);
  return (
    <>
      {isFuture(expiresAt) ? (
        <Green>Active till {expiresAt.toDateString()}</Green>
      ) : (
        <Red>Expired on {expiresAt.toDateString()}</Red>
      )}
      {subscriptionFlags.hasActiveFriendsReward && <Red> FriendsReward</Red>}
    </>
  );
};
