export const formatPrice = (amount: number) => {
  let roundedAmount = Math.ceil(amount);

  if (roundedAmount !== amount) {
    roundedAmount = roundedAmount - 0.01;
  }

  return roundedAmount;
};

export type TimezonePrice = {
  basePlanId: string;
  amount: number;
  currency: string;
  amountOriginal: number;
  currencyOriginal: string;
  offers: TimezonePriceOffer[];
};

export type TimezonePriceOffer = {
  offerId: string;
  reccurenceCount: number;
  duration: string; // ISO 8601 duration [P1W, P1M, P1Y, ...]
};

// find price data for a given storeId and timezonePrices. Sometimes base plans have offers (trial). We want to cut the offer part.
export const findTimezonePriceData = (storeId: string, timezonePrices: TimezonePrice[]) => {
  if (!timezonePrices || !Array.isArray(timezonePrices)) {
    console.error("Invalid timezonePrices:", timezonePrices);
    return null;
  }

  let priceData = timezonePrices?.find((t) => t.basePlanId === storeId);

  if (!priceData && storeId.split("@").length >= 2) {
    const modifiedStoreId = storeId.split("@").slice(0, -1).join("@");
    priceData = timezonePrices?.find((t) => t.basePlanId === modifiedStoreId);
  }

  return priceData;
};

export const isSubForMonth = (storeId: string) =>
  storeId.toLowerCase().includes("onemonth") ||
  storeId.toLowerCase().includes("1month") ||
  storeId.toLowerCase().includes("one-month");

export const isSubFor3months = (storeId: string) =>
  storeId.toLowerCase().includes("3months") ||
  storeId.toLowerCase().includes("threemonths") ||
  storeId.toLowerCase().includes("three-months");

export const isSubForYear = (storeId: string) => storeId.toLowerCase().includes("year");

export const isSubForFamily = (storeId: string) => storeId.toLowerCase().includes("family");

export const isSubWithTrial = (storeId: string) => storeId.toLowerCase().includes("trial");
