/**
 * @generated SignedSource<<8973654f62ec5f22c5e0d15ca7e1f7cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoginMethod = "apple" | "email" | "google" | "guest" | "%future added value";
export type PreLoginQuery$variables = {
  deviceId: string;
};
export type PreLoginQuery$data = {
  readonly preLogin: {
    readonly email: string | null;
    readonly token: string | null;
    readonly users: ReadonlyArray<{
      readonly email: string;
      readonly flamesCount: number;
      readonly lastLoginAt: number | null;
      readonly loginMethod: LoginMethod | null;
      readonly subscription: boolean | null;
    }> | null;
  } | null;
};
export type PreLoginQuery = {
  response: PreLoginQuery$data;
  variables: PreLoginQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      }
    ],
    "concreteType": "PreLoginReturnType",
    "kind": "LinkedField",
    "name": "preLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "deviceUsers",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flamesCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "loginMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastLoginAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscription",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreLoginQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreLoginQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ae0f67b1bb0aa6a7d55a22c566ee99c8",
    "id": null,
    "metadata": {},
    "name": "PreLoginQuery",
    "operationKind": "query",
    "text": "query PreLoginQuery(\n  $deviceId: String!\n) {\n  preLogin(deviceId: $deviceId) {\n    token\n    email\n    users {\n      email\n      flamesCount\n      loginMethod\n      lastLoginAt\n      subscription\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7211a9e63cb9bdc88fb57b304c2d5f40";

export default node;
