import { ComponentProps } from "react";

export function MammothBalloon(props: ComponentProps<"svg">) {
  return (
    <svg width="172" height="231" viewBox="0 0 172 231" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0952 144.996C40.7771 144.996 39.7076 146.095 39.7076 147.452C39.7076 156.939 32.2296 164.629 23.0058 164.629C13.7821 164.629 6.30105 156.939 6.30105 147.452C6.30105 146.095 5.23458 144.996 3.91641 144.996C2.59823 144.996 1.52856 146.095 1.52856 147.452V149.905C1.52856 163.458 12.2122 174.445 25.3905 174.445C38.5687 174.445 49.2523 163.458 49.2523 149.905V147.452C49.2523 146.095 48.1859 144.996 46.8677 144.996H42.0952Z"
        fill="#B5AB98"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.4571 153.062H78.7738L78.7706 206.517H65.4539L65.4571 153.062Z"
        fill="url(#paint0_linear_18_235)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.394 177.119H142.702L142.699 206.52H121.391L121.394 177.119Z"
        fill="#EA8627"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.4539 206.517H70.783V203.843C70.783 202.372 69.5844 201.169 68.1184 201.169C66.6524 201.169 65.4539 202.372 65.4539 203.843V206.517Z"
        fill="#B5AB98"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.1121 206.517H70.783V203.843C70.783 202.372 71.9815 201.169 73.4475 201.169C74.9135 201.169 76.1121 202.372 76.1121 203.843V206.517Z"
        fill="#B5AB98"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.1119 206.517H81.4414V203.843C81.4414 202.372 80.2425 201.169 78.7765 201.169C77.3105 201.169 76.1119 202.372 76.1119 203.843V206.517Z"
        fill="#B5AB98"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.391 206.517H126.721V203.843C126.721 202.372 125.522 201.169 124.056 201.169C122.59 201.169 121.391 202.372 121.391 203.843V206.517Z"
        fill="#B5AB98"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.05 206.517H126.72V203.843C126.72 202.372 127.919 201.169 129.385 201.169C130.851 201.169 132.05 202.372 132.05 203.843V206.517Z"
        fill="#B5AB98"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.05 206.517H137.379V203.843C137.379 202.372 136.18 201.169 134.714 201.169C133.248 201.169 132.05 202.372 132.05 203.843V206.517Z"
        fill="#B5AB98"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7738 153.062V206.517L102.746 206.52L102.768 182.823C102.768 179.673 105.372 177.119 108.581 177.119L142.233 177.122C145.445 177.122 148.047 179.676 148.047 182.827L148.025 206.52H171.997L172 153.596C172 122.104 141.013 96.5736 108.896 96.5736L108.861 95.7308C108.424 89.6945 103.403 83.5634 97.2655 83.5634H90.5835C84.4457 83.5634 79.2612 89.6881 78.8113 95.7276L78.7924 95.9802V95.9989L78.7799 96.2737V96.2801L78.7767 96.5703L78.7327 96.8799L75.4105 93.729L52.1245 93.6595L50.0861 96.8799H44.6373C38.361 96.8799 33.4943 101.679 33.4943 107.98V176.405C33.4943 189.005 43.6716 198.501 56.2236 198.501C65.6394 198.501 73.4475 191.821 73.4475 179.793H60.1278C60.1278 181.715 60.1278 183.802 57.4664 183.802C54.8048 183.802 54.8019 181.365 54.8019 179.793V153.062H78.7738Z"
        fill="url(#paint1_linear_18_235)"
      />
      <path
        d="M58.3531 130.788C59.9223 130.788 61.1943 129.511 61.1943 127.936C61.1943 126.362 59.9223 125.085 58.3531 125.085C56.784 125.085 55.512 126.362 55.512 127.936C55.512 129.511 56.784 130.788 58.3531 130.788Z"
        fill="#423D42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.7164 83.5634H58.3533C52.0773 83.5634 46.9873 89.246 46.9873 96.2515C46.9873 98.0038 48.2613 99.4245 49.8311 99.4245C51.3978 99.4245 52.6718 98.0038 52.6718 96.2515C52.6718 98.0038 53.9428 99.4245 55.5127 99.4245C57.0826 99.4245 58.3533 98.0038 58.3533 96.2515C58.3533 98.0038 59.6244 99.4245 61.1942 99.4245C62.7641 99.4245 64.0349 98.0038 64.0349 96.2515C64.0349 98.0038 65.3091 99.4245 66.8758 99.4245C68.4456 99.4245 69.7164 98.0038 69.7164 96.2515C69.7164 98.0038 70.9906 99.4245 72.5605 99.4245C74.1272 99.4245 75.4011 98.0038 75.4011 96.2515C75.4011 98.0038 76.6722 99.4245 78.2421 99.4245C79.8117 99.4245 81.0827 98.0038 81.0827 96.2515C81.0827 89.246 75.9956 83.5634 69.7164 83.5634Z"
        fill="url(#paint2_linear_18_235)"
      />
      <path
        d="M55.7517 147.667C55.0187 147.667 54.421 147.067 54.421 146.331C54.421 145.596 55.0187 144.996 55.7517 144.996H63.4655C64.7587 144.996 65.9666 144.595 66.9608 143.913C67.9927 143.209 68.8075 142.193 69.2698 141.021C69.5373 140.336 70.3113 140.002 70.9939 140.27C71.6766 140.538 72.0101 141.315 71.7427 142C71.0818 143.673 69.9275 145.116 68.4583 146.117C67.0362 147.092 65.3092 147.667 63.4655 147.667H55.7517Z"
        fill="#4B464B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.64 144.996C50.3218 144.996 49.2524 146.095 49.2524 147.452C49.2524 156.939 41.7744 164.629 32.5506 164.629C23.3269 164.629 15.8458 156.939 15.8458 147.452C15.8458 146.095 14.7794 144.996 13.4612 144.996C12.143 144.996 11.0734 146.095 11.0734 147.452V149.905C11.0734 163.458 21.757 174.445 34.9353 174.445C48.1135 174.445 58.7971 163.458 58.7971 149.905V147.452C58.7971 146.095 57.7307 144.996 56.4125 144.996H51.64Z"
        fill="url(#paint3_linear_18_235)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7766 206.517H84.106V203.843C84.106 202.372 82.9075 201.169 81.4415 201.169C79.9752 201.169 78.7766 202.372 78.7766 203.843V206.517Z"
        fill="#F0E1CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.4354 206.517H84.1062V203.843C84.1062 202.372 85.3048 201.169 86.7708 201.169C88.2368 201.169 89.4354 202.372 89.4354 203.843V206.517Z"
        fill="#F0E1CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.4353 206.517H94.7645V203.843C94.7645 202.372 93.5659 201.169 92.0999 201.169C90.6339 201.169 89.4353 202.372 89.4353 203.843V206.517Z"
        fill="#F0E1CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.034 206.517H153.363V203.843C153.363 202.372 152.165 201.169 150.699 201.169C149.233 201.169 148.034 202.372 148.034 203.843V206.517Z"
        fill="#F0E1CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.693 206.517H153.363V203.843C153.363 202.372 154.562 201.169 156.028 201.169C157.494 201.169 158.693 202.372 158.693 203.843V206.517Z"
        fill="#F0E1CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.693 206.517H164.022V203.843C164.022 202.372 162.823 201.169 161.357 201.169C159.891 201.169 158.693 202.372 158.693 203.843V206.517Z"
        fill="#F0E1CE"
      />
      <path
        d="M0 24.2149C0 10.8463 10.5855 0 23.6327 0C36.68 0 47.2655 10.8463 47.2655 24.2149C47.2655 37.1548 34.6121 48.7956 29.5902 52.9071C28.4454 53.8404 28.0516 55.48 28.7162 56.8168L31.3503 62.2021C31.4611 62.4291 31.5226 62.6814 31.5226 62.9336C31.5226 63.8291 30.8087 64.5606 29.9348 64.5606H17.343C16.4691 64.5606 15.7552 63.8291 15.7552 62.9336C15.7552 62.6814 15.8167 62.4291 15.9275 62.2021L18.5615 56.8168C19.2139 55.48 18.82 53.8404 17.6876 52.9071C12.6534 48.7956 0 37.1548 0 24.2149ZM11.8164 22.197C11.8164 16.6225 16.2229 12.1075 21.6633 12.1075C22.7465 12.1075 23.6327 11.1994 23.6327 10.0896C23.6327 8.97971 22.7465 8.07165 21.6633 8.07165C14.0442 8.07165 7.87758 14.3902 7.87758 22.197C7.87758 23.3069 8.76381 24.2149 9.84697 24.2149C10.9301 24.2149 11.8164 23.3069 11.8164 22.197Z"
        fill="#CF251D"
      />
      <path
        d="M22.6479 71.1483C20.3503 76.2572 15.755 89.615 15.755 102.175C15.755 117.876 24.6173 124.605 24.9455 151.52"
        stroke="#E2E2E0"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18_235"
          x1="72.1137"
          y1="206.517"
          x2="72.1137"
          y2="153.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B94B00" />
          <stop offset="0.760784" stopColor="#A74401" />
          <stop offset="1" stopColor="#943C02" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18_235"
          x1="58.388"
          y1="187.054"
          x2="147.397"
          y2="103.346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D76900" />
          <stop offset="1" stopColor="#FFA44F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_18_235"
          x1="55.5819"
          y1="100.93"
          x2="72.554"
          y2="82.117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D292D" />
          <stop offset="1" stopColor="#4B464B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_18_235"
          x1="58.7972"
          y1="159.72"
          x2="11.0734"
          y2="159.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0E1CD" />
          <stop offset="1" stopColor="#F8F0E6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
