import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { devicePaddings } from "../../tools/device";
import UndoIcon from "@mui/icons-material/Undo";
import FilterIcon from "../../icons/FilterIcon";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { useSaleExpiration, useSubscribe, useSubscriptions } from "../../root/profile/subscription/subscriptionHooks";
import { FIRSTSUBSCRIPTION } from "../../root/tutorial/misc/FirstSubscription";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useNavigate } from "react-router";
import SubscriptionPromocode from "../../root/profile/subscription/SubscriptionPromocode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette, faRectangleVerticalHistory } from "@fortawesome/pro-regular-svg-icons";
import { useTimedActions } from "../../hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
import { useMaxCardsToLearn } from "../dialogs/useMaxCardsToLearn";

const { top } = devicePaddings();

const Wrap = styled.div`
  height: 100%;
  padding: ${top + 55}px 0;
  color: white;
  background: ${({ theme }) => theme.duo.color.themeGradient};

  .hidden {
    opacity: 0;
    visibility: hidden;
  }

  .showing {
    opacity: 1;
    transition: opacity 1s;
  }
  .showing.op-80 {
    opacity: 0.8;
  }
`;
const In = styled.div`
  display: flex;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
const Top = styled.div`
  font-size: 32px;
  font-family: "Baloo Chettan 2", Arial, sans-serif;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  margin: 10px 0;
  text-align: center;
  line-height: 1em;
  cursor: pointer;
`;
const Li = styled.div`
  padding: 8px 0;
  text-align: center;
  line-height: 1.4;
  opacity: 0;
  transition: opacity 1000ms;
  svg {
    margin: 0 7px;
    font-size: 30px;
  }
`;
const SubLi = styled.div`
  color: #ddd;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.3em;
`;
const SButton = styled(Button)`
  &&& {
    background: white;
    color: ${({ theme }) => theme.duo.color.primary};
    padding: 11px 40px;
    font-size: 17px;
    margin: 16px 0;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
`;
const Nowrap = styled.span`
  white-space: nowrap;
`;
const Price = styled.div`
  margin: 25px 0 0;
  font-weight: bold;
`;
const CloseButton = styled.div`
  padding: 3px;
  border-radius: 30px;
  font-size: 80%;
  position: absolute;
  top: ${top + 10}px;
  right: 10px;
  opacity: 0;
  transition: all 1s;
  color: white;
  display: flex;
  svg {
    margin-left: 5px;
  }
`;
const SSubscriptionPromocode = styled(SubscriptionPromocode)`
  color: rgb(221, 221, 221);
  display: block;
`;

type Props = { onClose: VoidFunction };

const InternAdOverview: React.FC<Props> = ({ onClose }) => {
  const { subscriptions } = useSubscriptions();
  const { storeIdWithSale } = useSaleExpiration();
  const { hintWasSeen, viewer, isSubscriber } = useViewerQuery();
  const subscribe = useSubscribe();
  const navigate = useNavigate();
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  const TOLEARNMAX = useMaxCardsToLearn();

  useTimedActions([
    { delay: 500, callback: () => setVisibleIndex(1) },
    { delay: 1500, callback: () => setVisibleIndex(2) },
    { delay: 2500, callback: () => setVisibleIndex(3) },
    { delay: 2500, callback: () => setVisibleIndex(4) }
  ]);

  const order = (e) => {
    if (subscriptions) {
      if (!hintWasSeen(FIRSTSUBSCRIPTION)) {
        subscribe();
      } else {
        navigate(SUBSCRIPTION.url());
      }
    } else {
      onClose();
    }
    e.stopPropagation();
  };

  React.useEffect(() => {
    if (isSubscriber) {
      onClose();
    }
  }, [isSubscriber, onClose]);

  return (
    <Wrap>
      <CloseButton onClick={onClose} className={visibleIndex >= 4 ? "showing op-80" : "hidden"}>
        <Trans>Skip for now</Trans>
        <CloseIcon />
      </CloseButton>
      <In>
        <Top>DuoCards Premium</Top>
        <Li style={{ fontSize: "110%" }} className={visibleIndex >= 1 ? "showing" : "hidden"}>
          <FontAwesomeIcon icon={faRectangleVerticalHistory} style={{ fontSize: "25" }} />
          <div>
            <Trans>Unlimited count of cards</Trans>
            <SubLi>
              <Trans>only {String(TOLEARNMAX)} "to learn" are for free</Trans>
            </SubLi>
          </div>
        </Li>
        <Li style={{ fontSize: "120%" }} className={visibleIndex >= 2 ? "showing" : "hidden"}>
          <NotInterestedIcon />
          <Trans parent="div">Ad-free studying</Trans>
        </Li>
        <Li className={visibleIndex >= 3 ? "showing" : "hidden"}>
          <Icons>
            <FilterIcon style={{ width: 20, height: 20, fill: "white" }} />
            <UndoIcon />
            <FontAwesomeIcon icon={faPalette} style={{ fontSize: "25" }} />
            <RecordVoiceOverIcon />
          </Icons>
          <div>
            <Trans>Filtering, Undo button, Colours</Trans>, <Trans>Voices</Trans>,{" "}
            <Trans parent={Nowrap}>Unlimited study decks</Trans>
          </div>
        </Li>
        <div>
          {subscriptions && (
            <Price>
              {subscriptions[storeIdWithSale]?.price} / <Trans>1 Year</Trans>
            </Price>
          )}
          {!viewer?.installReferrer && <SSubscriptionPromocode onSuccess={onClose} />}
        </div>
        <SButton variant="contained" color="primary" onClick={order}>
          {subscriptions ? <Trans>Start free trial</Trans> : <Trans>Subscribtion is not available</Trans>}
        </SButton>
      </In>
    </Wrap>
  );
};

export default InternAdOverview;
