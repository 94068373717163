import React from "react";
import DialogWindow from "../../../../components/other/DialogWindow";
import styled from "styled-components/macro";
import { Button, Stack, Typography, Box, useTheme } from "@mui/material";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import { SUBSKICKHINT } from "../../../../sharedJs__generated/constants";
import { useMutation } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { SubsKickerMutation } from "./__generated__/SubsKickerMutation.graphql";
import { SUBSCRIPTION } from "../../../profile/ProfileRouter.config";
import { MammothBalloon } from "../../../../icons/MammothBalloon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { isApp } from "../../../../tools/device";

const seeHintQL = graphql`
  mutation SubsKickerMutation($name: String!) {
    seeHint(name: $name) {
      seenHints {
        name
        when
      }
      showSubsKickDialog
    }
  }
`;

const Wrap = styled.div`
  padding: 0 12px;
  font-size: 95%;
  line-height: 1.45em;

  table {
    max-width: 330px;
    display: block;
    margin: auto;
    border-collapse: collapse;
    border-radius: 6px;
    overflow: hidden;
  }
  td {
    padding: 4px 10px;
    text-align: left;
  }
`;

type CompProps = {
  onClose: VoidFunction;
};

const SubsKickerComponent: React.FC<CompProps> = React.memo(({ onClose }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <DialogWindow open={true} wide={isApp()} onClose={onClose}>
      <Wrap>
        <Stack direction="column" spacing={3}>
          <Typography variant="h6">
            <Trans>Hello!</Trans>
          </Typography>
          <Box sx={{ maxWidth: 350, textAlign: "center" }}>
            <Trans>
              We are very happy that you are using our app in your studies. We believe now is the right time to{" "}
              <strong>upgrade to a Premium account</strong>.
            </Trans>
          </Box>
          <Box sx={{ padding: "0 10px" }}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faCheck} color={theme.duo.palette.blueMain} />
                  </td>
                  <td>
                    <Trans>No ads.</Trans>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faCheck} color={theme.duo.palette.blueMain} />
                  </td>
                  <td>
                    <Trans>All premium features.</Trans>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faCheck} color={theme.duo.palette.blueMain} />
                  </td>
                  <td>
                    <Trans>No restrictions on studying.</Trans>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faPlus} color={theme.duo.color.green} />
                  </td>
                  <td>
                    <Trans>You'll support further development.</Trans>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClose();
                navigate(SUBSCRIPTION.url());
              }}
            >
              <Trans>I want to switch to Premium</Trans>
            </Button>
          </Box>
          <Box>
            <MammothBalloon style={{ maxHeight: "150px" }} />
          </Box>
        </Stack>
      </Wrap>
    </DialogWindow>
  );
});

const SubsKicker = () => {
  const { viewer } = useViewerQuery();
  const [seeHint] = useMutation<SubsKickerMutation>(seeHintQL);

  const handleClose = React.useCallback(() => {
    seeHint({
      variables: { name: SUBSKICKHINT },
      optimisticUpdater: (store) => {
        const viewerRec = viewer?.id && store.get(viewer.id);
        viewerRec && viewerRec?.setValue(false, "showSubsKickDialog");
      }
    });
  }, [seeHint, viewer?.id]);

  if (!viewer?.showSubsKickDialog || viewer?.subscription) return null;

  return <SubsKickerComponent onClose={handleClose} />;
};

export default SubsKicker;
