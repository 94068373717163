/**
 * @generated SignedSource<<79702d918440d518c73bae9d27a6543e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionPlatform = "android" | "browser" | "ios" | "lemon" | "%future added value";
export type SubscribeManuallyMutation$variables = {
  family: boolean;
  userId: string;
};
export type SubscribeManuallyMutation$data = {
  readonly subscribeManually: {
    readonly subscriptions: ReadonlyArray<{
      readonly family: boolean;
      readonly platform: SubscriptionPlatform;
      readonly storeId: string;
      readonly timeToExpire: number;
      readonly transactionId: string;
    }>;
  } | null;
};
export type SubscribeManuallyMutation = {
  response: SubscribeManuallyMutation$data;
  variables: SubscribeManuallyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "family"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "family",
    "variableName": "family"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscribeManuallyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "subscribeManually",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscribeManuallyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "subscribeManually",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d3774f32055b7d5bb9be431744a3f61",
    "id": null,
    "metadata": {},
    "name": "SubscribeManuallyMutation",
    "operationKind": "mutation",
    "text": "mutation SubscribeManuallyMutation(\n  $family: Boolean!\n  $userId: ID!\n) {\n  subscribeManually(family: $family, userId: $userId) {\n    subscriptions {\n      transactionId\n      timeToExpire\n      platform\n      storeId\n      family\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f81a9ba313f74b6555cb7f4e93a07092";

export default node;
