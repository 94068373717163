import { isNoPaymentTimezone } from "components/other/VpnAffiliate";
import { useViewerQuery } from "queries/viewerQuery";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { dividePrice } from "tools/price";
import { useSaleExpiration, useSubscriptions } from "./subscriptionHooks";
import { StoreId, useStoreIds } from "./subscriptionIds";
import { isSubFor3months } from "sharedJs__generated/functions";

const Countdown = styled.div`
  position: absolute;
  bottom: -12px;
  left: -2px;
  right: -2px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.duo.color.green};
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 5px;
`;
const MonthlyPrice = styled.div<{ isInSale: boolean }>`
  color: ${({ isInSale, theme }) => (isInSale ? theme.duo.color.green : "#09D")};
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
`;

const SalePrice = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
`;

const Crossed = styled.del`
  font-size: 11px;
  line-height: 1em;
  margin: 8px 0 0;
`;

const PriceBox = ({ plan }: { plan: StoreId }) => {
  const { subscriptions } = useSubscriptions();
  const { viewer, subscriptionFlags } = useViewerQuery();
  const { saleExpiration, saleDiscount, saleActive } = useSaleExpiration();
  const [timeLeft, setTimeLeft] = useState(saleExpiration);
  const { storeIdYear, storeIdFamily, storeIdMonth } = useStoreIds();
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  if (!subscriptions) return <>N / A</>;

  const months = isSubFor3months(plan) ? 3 : 12;
  const haveMonthPrice = plan !== storeIdMonth || isSubFor3months(plan);
  const monthPrice = haveMonthPrice
    ? dividePrice(subscriptions[plan]?.price || "", subscriptions[plan]?.priceMicros || 0, months) || null
    : null;

  const canShowSale =
    !viewer?.subscription && saleActive && plan !== storeIdMonth && !isNoPaymentTimezone(viewer?.timezone);

  const formatTimeLeft = (time) => {
    if (!time) return null;

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const extraPrice =
    plan === storeIdYear
      ? subscriptions[storeIdFamily]?.price
      : dividePrice(subscriptions[storeIdFamily]?.price || "", subscriptions[storeIdFamily]?.priceMicros, 0.66);

  return (
    <>
      {monthPrice && (
        <MonthlyPrice isInSale={canShowSale}>
          {monthPrice} / <Trans>month</Trans>
        </MonthlyPrice>
      )}
      {canShowSale ? (
        <>
          <Crossed>{extraPrice}</Crossed>
          <SalePrice>{subscriptions[plan]?.price}</SalePrice>
        </>
      ) : (
        <div>
          <>{subscriptions[plan]?.price}</>
        </div>
      )}
      {plan === storeIdYear && ((!canShowSale && timeLeft) || !subscriptionFlags.hadSubscription) && (
        <Countdown>
          {canShowSale && timeLeft
            ? `-${saleDiscount}% | ${formatTimeLeft(timeLeft)}`
            : !subscriptions[storeIdYear]?.isTrial
              ? `-${saleDiscount}% | ${t("season")}`
              : "7 days free"}
        </Countdown>
      )}
    </>
  );
};

export default PriceBox;
