import { PICKED_FEW_CARDS } from "components/fire/xpHooks";
import { useSeeHint } from "components/hint/Hint";
import { MINWIDTH } from "components/initializers/ViewportKiller";
import { useGetState, useSetState } from "components/ReduxProvider";
import Garden from "icons/garden/Garden";
import { useViewerQuery, useViewerXps } from "queries/viewerQuery";
import React from "react";
import { useEffectOnce, usePreviousDistinct } from "react-use";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { LOSING_XPS_HINT, SHARE_AND_CHROME_QUEST, STREAK_FREEZE_EARN_QUEST } from "sharedJs__generated/constants";
import styled from "styled-components/macro";
import DailyXps, { DAILYXPS } from "./DailyXps";
import QuestsBackdrop from "./everySource/QuestsBackdrop";
import { FIRSTPET } from "../../../../components/mammoth/PetableMammoth";
import { OWNWORDQUEST } from "./everySource/OwnWord";
import AiAssistantQuest, { AI_ASSISTANT_QUEST } from "./AiAssistantQuest";
import InviteFriendQuest from "./inviteFriend/InviteFriendQuest";
import { INVITE_FRIEND_QUEST } from "./inviteFriend/InviteFriendQuest.model";
import StreakFreezeQuest from "./streakFreeze/StreakFreezeQuest";
import { MotivationBubble } from "./MotivationBubble";
import { OwnWordQuest } from "./everySource/OwnWordQuest";
import { ShareAndChromeQuest } from "./everySource/ShareAndChromeQuest";
import { LosingXpsQuest } from "./LosingXpsQuest";
import { FewMoreCardsQuest } from "./FewMoreCardsQuest";

export const QUESTS_ID = "questsId";
export const GARDENWIDTH = MINWIDTH + 25;

const Wrap = styled.div<{ $scale?: number }>`
  min-width: 340px;
  margin: auto 0;
  padding: 12px 0;
  transform-origin: left top;
  transform: scale(${({ $scale }) => ($scale ? $scale : 1)});
`;

type Props = {};

const Quests: React.FC<Props> = () => {
  const { hintWasSeen, isGuest, getFlag } = useViewerQuery();
  const { xps } = useViewerXps();
  const petting = useGetState("pettingMemo");
  const lastSeenTotalXp = useGetState("lastSeenTotalXp");
  const setLastSeenTotalXp = useSetState("lastSeenTotalXp");
  const prevClaimed = usePreviousDistinct(hintWasSeen(PICKED_FEW_CARDS));
  const seeHint = useSeeHint();
  const showQuestsBackdrop = useGetState("showQuestsBackdrop");
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const [marginScaled, setMarginScaled] = React.useState(12); // 12px is convenient for many phones with 360px width
  const setPresent = useSetState("present");

  const abtest_loosingXpRemoved = getFlag("abtest_loosingXpRemoved")?.value;

  useEffectOnce(() => {
    setPresent(false);
  });

  React.useEffect(() => {
    if (petting && !hintWasSeen(FIRSTPET) && hintWasSeen(TUTORIALDONE)) {
      setTimeout(() => seeHint(FIRSTPET), 500);
    }
  }, [petting, hintWasSeen, seeHint]);

  React.useEffect(() => {
    if (xps && lastSeenTotalXp !== xps.total) {
      setLastSeenTotalXp(xps.total);
    }
  }, [lastSeenTotalXp, xps, setLastSeenTotalXp]);

  const scale = GARDENWIDTH > window.innerWidth ? Number((window.innerWidth / GARDENWIDTH).toFixed(2)) : undefined;
  React.useEffect(() => {
    if (scale && wrapRef.current) {
      setMarginScaled(Math.round(wrapRef.current.offsetHeight * (1 - scale)));
    }
  }, [scale]);

  if (!hintWasSeen(TUTORIALDONE) || !xps) return null;

  const canPet = (xps.today || 0) >= DAILYXPS;

  const getQuestComponent = () => {
    if (!hintWasSeen(PICKED_FEW_CARDS)) {
      return FewMoreCardsQuest;
    } else if (!hintWasSeen(OWNWORDQUEST)) {
      return OwnWordQuest;
    } else if (!hintWasSeen(SHARE_AND_CHROME_QUEST)) {
      return ShareAndChromeQuest;
    } else if (abtest_loosingXpRemoved !== "on" && !hintWasSeen(LOSING_XPS_HINT)) {
      return LosingXpsQuest;
    } else if (!hintWasSeen(STREAK_FREEZE_EARN_QUEST)) {
      return StreakFreezeQuest;
    } else if (!hintWasSeen(AI_ASSISTANT_QUEST)) {
      return AiAssistantQuest;
    } else if (!hintWasSeen(INVITE_FRIEND_QUEST) && !isGuest) {
      return InviteFriendQuest;
    }

    if (!canPet) {
      return DailyXps;
    }

    return MotivationBubble;
  };

  const QuestComponent = getQuestComponent();

  return (
    <Wrap id={QUESTS_ID} $scale={scale} ref={wrapRef} style={{ marginBottom: scale ? -marginScaled : undefined }}>
      <QuestsBackdrop active={!!showQuestsBackdrop} />

      <QuestComponent />

      <Garden
        xps={{
          ...xps,
          total: lastSeenTotalXp !== undefined && lastSeenTotalXp < xps.total ? lastSeenTotalXp : xps.total
        }}
        allowPetting={canPet}
        noDeadFire={!hintWasSeen(PICKED_FEW_CARDS) || prevClaimed === false}
      />
    </Wrap>
  );
};

export default Quests;
