import React from "react";
import { IconButton, Dialog, DialogProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled, { css } from "styled-components/macro";
import { devicePaddings, isDesktop } from "tools/device";

const { top, bottom } = devicePaddings();

const SDialog = styled(Dialog)<{ $background?: boolean; $canPopup: boolean; $maxWidth: number }>`
  ${({ $background }) =>
    $background &&
    css`
      .MuiPaper-root {
        background: ${({ theme }) => theme.duo.color.lightGrey};
      }
    `}

  ${(p) =>
    p.$canPopup &&
    css`
      @media (min-width: ${p.$maxWidth}px) {
        & > .MuiDialog-container > .MuiPaper-root {
          background-color: transparent;
        }
      }
    `}
`;
const Wrap = styled.div<{ padded: boolean; $maxWidth: number; $canPopup: boolean }>`
  padding: ${({ padded }) => (padded ? top + 50 : top)}px 0 ${({ padded }) => (padded ? bottom + 20 : bottom)}px;
  margin: 0 auto;
  width: 100%;
  max-width: ${(p) => p.$maxWidth}px;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${(p) =>
    p.$canPopup &&
    css`
      @media (min-width: ${p.$maxWidth}px) {
        margin: 32px auto;
        border-radius: 10px;
        overflow-y: auto;
      }
    `}
`;
const SIconButton = styled(IconButton)<{ $maxWidth: number; $canPopup: boolean; $consumeHeader: boolean }>`
  position: absolute;
  right: 6px;
  top: ${(p) => (p.$consumeHeader ? "8" : "-40")}px;
  z-index: 1;

  ${(p) =>
    p.$canPopup &&
    css`
      @media (min-width: ${p.$maxWidth}px) {
        top: 8px;
      }
    `}
`;
const CloseWrap = styled.div`
  position: relative;
`;

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose?: VoidFunction;
  className?: string;
  background?: boolean;
  TransitionComponent?: DialogProps["TransitionComponent"];
  allowDesktopPopup?: boolean;
  maxWidth?: number;
  consumeHeader?: boolean;
};
const DialogFullWindow: React.FC<Props> = ({
  open,
  children,
  onClose,
  className,
  background,
  TransitionComponent,
  allowDesktopPopup,
  maxWidth,
  consumeHeader
}) => {
  const canPopup = !!allowDesktopPopup && isDesktop();
  maxWidth ||= 800;

  return (
    <SDialog
      fullScreen
      open={open}
      onClose={onClose}
      $background={background}
      $canPopup={canPopup}
      $maxWidth={maxWidth}
      TransitionComponent={TransitionComponent}
      PaperProps={{
        sx: {
          border: "none"
        }
      }}
    >
      <Wrap padded={!!onClose && !consumeHeader} className={className} $canPopup={canPopup} $maxWidth={maxWidth}>
        {onClose && (
          <CloseWrap>
            <SIconButton onClick={onClose} $canPopup={canPopup} $maxWidth={maxWidth} $consumeHeader={!!consumeHeader}>
              <CloseIcon />
            </SIconButton>
          </CloseWrap>
        )}
        {children}
      </Wrap>
    </SDialog>
  );
};

export default DialogFullWindow;
