import React from "react";
import { useLocation, useMatch, useNavigate } from "react-router";
import { FIVEHINT } from "root/main/main/Main";
import { useMatchMain } from "root/RootRouter";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import { FIRSTLOOPDONE } from "root/main/learn/Learn";
import { TUTORIALDONE } from "./steps/Verdict";
import { DECK } from "root/main/MainRouter";
import { LIBRARY, MAIN } from "../RootRouter.config";
import { PETMAMMOTH } from "./steps/PetMammoth";
// if user is still in first loop, should be at source at all
export const useRedirectIfTutorialInLoop = () => {
  const { hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();
  const inLibrary = useMatch(LIBRARY.root() + "/*");
  React.useEffect(() => {
    if (inLibrary && !hintWasSeen(TUTORIALDONE) && !hintWasSeen(FIRSTLOOPDONE) && hintWasSeen(FIVEHINT)) {
      navigate(MAIN.url(), { replace: true });
    }
  });
};

export const useRedirectToDeckIfNoDecks = () => {
  const { decks } = useViewerDecks();
  const inMain = useMatchMain();
  const navigate = useNavigate();
  const location = useLocation();
  const { hintWasSeen } = useViewerQuery();

  React.useEffect(() => {
    const hasOnboardingCompleted = hintWasSeen(PETMAMMOTH);
    if (!hasOnboardingCompleted) return;

    if (decks !== undefined && !decks?.length && inMain && location.pathname !== DECK.url()) {
      navigate(DECK.url(), { state: { noBack: true } });
    }
  }, [decks, navigate, location.pathname, inMain, hintWasSeen]);
};
