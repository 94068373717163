import React from "react";
import styled from "styled-components/macro";
import { useViewerDecks } from "queries/viewerQuery";
import { AssistantMode } from "../__generated__/AssistantMutation.graphql";
import { pickSuggestionsTranslations, useGetSuggestions } from "./suggestionsHooks";
import Toggler from "../../other/Toggler";
import { Stack } from "@mui/material";
import { SuggestionDefinition, TranslatedSuggestion } from "./suggestionsDefinitions";
import ChatSuggestion from "./ChatSuggestion";
import { usePrevious } from "react-use";

const TWO_ROWS_TRESHOLD = 4;

const SliderWrap = styled(Stack)`
  width: 100%;
  overflow-x: auto;
  background: ${(p) => p.theme.duo.color.secondLevelBg};
  padding: 14px 20px;
`;
const SStack = styled(Stack)`
  &:after {
    content: "_";
    opacity: 0;
  }
`;

type Props = {
  onUseSuggestion: (suggestion: TranslatedSuggestion) => void;
  messagesCount: number;
  chatMode: AssistantMode;
  autoApplySuggestion?: SuggestionDefinition;
};
const ChatSuggestions: React.FC<Props> = React.memo(
  ({ onUseSuggestion, messagesCount, chatMode, autoApplySuggestion }) => {
    const { deck } = useViewerDecks();
    const [expanded, setExpanded] = React.useState(false);
    const { isLoading, translatedSuggestions } = useGetSuggestions(
      chatMode,
      messagesCount,
      deck?.back || "en",
      deck?.front || "en"
    );
    const suggestionAutoAppliedRef = React.useRef<boolean>(false);
    const canShow = expanded && (!autoApplySuggestion || suggestionAutoAppliedRef.current); // we don't want to flash suggestions when it should apply suggestion right away
    const previousSuggestions = usePrevious(translatedSuggestions);
    const currentSuggestions = expanded ? translatedSuggestions : previousSuggestions || []; // keeps suggestions in the box when toggler closes
    const firstLine =
      currentSuggestions.length < TWO_ROWS_TRESHOLD
        ? currentSuggestions
        : currentSuggestions.filter((s, i) => i % 2 === 0);
    const secondLine =
      currentSuggestions.length < TWO_ROWS_TRESHOLD ? [] : currentSuggestions.filter((s, i) => i % 2 === 1);

    React.useEffect(() => {
      setExpanded(!isLoading && !!translatedSuggestions.length);
    }, [isLoading, translatedSuggestions.length]);

    React.useEffect(() => {
      if (!autoApplySuggestion || suggestionAutoAppliedRef.current || isLoading) return;

      const translatedSuggestion: TranslatedSuggestion | undefined = pickSuggestionsTranslations(
        [autoApplySuggestion],
        translatedSuggestions
      )[0];

      if (translatedSuggestion) {
        handleClick(translatedSuggestion);
        suggestionAutoAppliedRef.current = true;
      }
    });

    const handleClick = (suggestion: TranslatedSuggestion) => {
      setExpanded(false);
      onUseSuggestion(suggestion);
    };

    return (
      <Toggler show={canShow}>
        <SliderWrap direction="column" spacing={2}>
          <SStack direction="row" spacing={2}>
            {firstLine.map((suggestion, i) => (
              <ChatSuggestion onClick={handleClick} suggestion={suggestion} key={i}></ChatSuggestion>
            ))}
          </SStack>
          {!!secondLine.length && (
            <SStack direction="row" spacing={2}>
              {secondLine.map((suggestion, i) => (
                <ChatSuggestion onClick={handleClick} suggestion={suggestion} key={i}></ChatSuggestion>
              ))}
            </SStack>
          )}
        </SliderWrap>
      </Toggler>
    );
  }
);

export default ChatSuggestions;
