import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Stack, Button } from "@mui/material";
import LoginGuest from "./LoginGuest";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { bottomPadding, isDesktop, topPadding, isApp } from "../../tools/device";
import LoginHeader from "./LoginHeader";
import OAuth from "components/other/OAuth";
import { useSetState, useGetState } from "components/ReduxProvider";
import EmailAuth from "root/auth/EmailAuth";
import Toggler from "components/other/Toggler";
import { usePreLoginQuery } from "./PreLogin";
import MammothNew from "icons/MammothNew";
import { BrandTitle } from "../../components/other/BrandTitle";
import ContinueWithEmailButton from "./ContinueWithEmailButton";
import { dispatchEvent, EVENT } from "tools/events";
import { useEffectOnce } from "react-use";
import { UserAction } from "components/initializers/UserActionsLogger";
import { ReassuranceScreen } from "./reassurance/ReassuranceScreen";

export const ForceLoginPageHash = "login";
export const LoginPageForced = () => {
  return window.location.hash === `#${ForceLoginPageHash}`;
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  text-align: center;
  padding: ${topPadding}px 0 ${bottomPadding}px;
  .MuiButton-outlined {
    background: white;
  }
`;
const In = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 5px 24px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: stretch;
  margin: 0 auto;
  padding: 5px 24px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
  height: 100%;
`;
const SliderOut = styled.div`
  width: 100%;
  overflow: hidden;
`;
const SliderBox = styled.div`
  width: 50%;
  padding: 8px 0;
`;
const SliderIn = styled.div<{ index: number; size: number }>`
  width: ${(p) => p.size * 100}%;
  position: relative;
  left: ${(p) => p.index * -100}%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: left 0.3s;

  ${SliderBox} {
    width: ${(p) => 100 / p.size}%;
  }
`;
const Small = styled.p`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const SContinueWithEmailButton = styled(ContinueWithEmailButton)`
  margin-bottom: 20px;
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: preLoginData, isLoading: preLoginLoading } = usePreLoginQuery();
  const [isEmailOpen, setEmailOpen] = useState(false);
  const [isAuthOpen] = useState(true);
  const [areLoginOptionsOpen, setLoginOptionsOpen] = useState(false);
  const location = useLocation();
  const [mammothHeight, setMammothHeight] = useState(120);
  const [subtitleVisible, setSubtitleVisible] = useState(true);
  const setToken = useSetState("token");
  const [showReassurance, setShowReassurance] = useState(true);

  // todo: remove whole block below after ab-test
  const abtest_newReassurance2 = useGetState("abtest_newReassurance2");
  const set_abtest_newReassurance2 = useSetState("abtest_newReassurance2");
  useEffectOnce(() => {
    const isABTestActive = Math.random() > 0.5;
    set_abtest_newReassurance2(isABTestActive ? "on" : "off"); // to be stored by dblog "user_action"
  });

  if (preLoginData?.token) {
    setToken(preLoginData.token);
  }

  const toggleEmailMethod = (show: boolean) => {
    setEmailOpen(show);
    navigate({ hash: show ? "email" : "" });
  };

  const handleKeyboardToggle = (isVisible: boolean) => {
    if (isDesktop()) return;
    setSubtitleVisible(!isVisible);
  };

  useEffect(() => {
    setMammothHeight(isEmailOpen && !isDesktop() ? 50 : 120);
  }, [isEmailOpen]);

  useEffect(() => {
    if (location.hash === "#email" && !isEmailOpen) {
      setEmailOpen(true);
    }

    if (location.hash !== "#email" && isEmailOpen) {
      setEmailOpen(false);
    }
  }, [location.hash, isEmailOpen]);

  useEffectOnce(() => {
    dispatchEvent(EVENT.ATTConsent);
    dispatchEvent(EVENT.logUserAction, { action: UserAction.signupVisited });
  });

  const googleAuth = <OAuth hideApple />;
  const appleAuth = <OAuth hideGoogle />;
  const emailAuth = <SContinueWithEmailButton onClick={() => toggleEmailMethod(true)} />;
  const guestAuth = <LoginGuest />;

  if (preLoginLoading) {
    return null;
  }

  if (showReassurance && abtest_newReassurance2 === "on") {
    return (
      <ReassuranceScreen
        onLogin={() => {
          setShowReassurance(false);
          setLoginOptionsOpen(true);
        }}
      />
    );
  }

  let slideIndex: number;
  if (areLoginOptionsOpen) {
    slideIndex = isEmailOpen ? 2 : 1;
  } else {
    slideIndex = 0;
  }

  return (
    <Wrap>
      <LoginHeader
        onBack={() => {
          setShowReassurance(true);
          setLoginOptionsOpen(false);
        }}
        showBack={areLoginOptionsOpen && !isEmailOpen && (isApp() || isAuthOpen)}
      />
      <div>
        <Stack alignItems="center" spacing={1}>
          <MammothNew height={mammothHeight} style={{ transition: "height 0.5s ease" }} />
          <BrandTitle>DuoCards</BrandTitle>
          {subtitleVisible && <Trans parent={Small}>Add words from anywhere, never forget them</Trans>}
        </Stack>
      </div>
      <Toggler show={isAuthOpen}>
        <SliderOut>
          <SliderIn size={3} index={slideIndex}>
            <SliderBox>
              <Bottom>
                <Stack direction="column" spacing={3} sx={{ margin: "0 30px 22px" }}>
                  <div>
                    <Button
                      variant="text"
                      color="inherit"
                      fullWidth
                      onClick={() => setLoginOptionsOpen(!areLoginOptionsOpen)}
                    >
                      {t("I already have an account")}
                    </Button>
                  </div>
                  <div>{guestAuth}</div>
                </Stack>
              </Bottom>
            </SliderBox>
            <SliderBox>
              <In>
                <div>{googleAuth}</div>
                <div>{appleAuth}</div>
                <div>{emailAuth}</div>
              </In>
            </SliderBox>
            <SliderBox>
              {isEmailOpen && (
                <EmailAuth
                  initialEmail={preLoginData?.email || undefined}
                  onBackButton={() => toggleEmailMethod(false)}
                  onFocus={() => handleKeyboardToggle(true)}
                  onBlur={() => handleKeyboardToggle(false)}
                />
              )}
            </SliderBox>
          </SliderIn>
        </SliderOut>
      </Toggler>
      {isDesktop() && <div></div>} {/* empty to do not stick to bottom on desktop */}
    </Wrap>
  );
};

export default LoginPage;
