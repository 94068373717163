import { Viewer } from "../viewerQuery";
import { FRIENDSREWARD, MANUALSTOREID, Platform } from "../../sharedJs__generated/constants";

export type SubscriptionFlags = {
  hasActiveFriendsReward: boolean;
  hasActiveMonthlySub: boolean;
  hasActiveYearlySub: boolean;
  hasActiveFamilySub: boolean;
  isFamilyFather: boolean;
  isAndroidSubscription: boolean;
  isIosSubscription: boolean;
  isLemonSubscription: boolean;
  isSubscriptionCancelled: boolean;
  hadSubscription: boolean;
  hadIosSubscription: boolean;
};

export const getSubscriptionFlags = (
  subscription?: Viewer["subscription"] | null,
  subscriptions?: Viewer["subscriptions"] | null,
  name?: string | null
): SubscriptionFlags => {
  if (!subscription) {
    return {
      hasActiveFriendsReward: false,
      hasActiveMonthlySub: false,
      hasActiveYearlySub: false,
      hasActiveFamilySub: false,
      isFamilyFather: false,
      isAndroidSubscription: false,
      isIosSubscription: false,
      isLemonSubscription: false,
      isSubscriptionCancelled: false,
      hadSubscription: !!subscriptions?.length,
      hadIosSubscription: !!subscriptions?.some((s) => s.platform === Platform.ios)
    };
  }

  const friendsReward = subscription.storeId === FRIENDSREWARD;
  const manual = subscription.storeId === MANUALSTOREID;

  return {
    hasActiveFriendsReward: friendsReward,
    hasActiveMonthlySub: subscription.storeId.indexOf("month") >= 0,
    hasActiveYearlySub: subscription.storeId.indexOf("month") === -1 && !friendsReward && !manual,
    hasActiveFamilySub: subscription.family,
    isFamilyFather:
      subscription.family && subscription.famembers?.filter((member) => member.name === name).length !== 1,
    isAndroidSubscription: subscription.platform === Platform.android,
    isIosSubscription: subscription.platform === Platform.ios,
    isLemonSubscription: subscription.platform === Platform.lemon,
    isSubscriptionCancelled: subscription.cancelled !== null,
    hadSubscription: true,
    hadIosSubscription: !!subscriptions?.some((s) => s.platform === Platform.ios)
  };
};
