import Sun from "icons/garden/sun/Sun";
import React from "react";
import styled from "styled-components/macro";

const Wrap = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const SSun = styled(Sun)`
  width: 45px;
  height: 45px;
`;
const Days = styled.span`
  position: relative;
  font-weight: bold;
  color: white;
  font-size: 14px;
  text-shadow: 0 0 2px #333;
`;

type Props = {
  days: number;
};

const Streak: React.FC<Props> = ({ days }) => {
  return (
    <Wrap className="nofilter">
      <SSun />
      <Days>{days}</Days>
    </Wrap>
  );
};

export default Streak;
