import { addSeconds } from "date-fns";
import { useDeviceLang } from "hooks/deviceHooks";
import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Wrap = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
  font-size: 13px;
  font-weight: bold;
  margin: 4px 0;
`;

type Props = { cancelled?: boolean };

const SubscribedUntil: React.FC<Props> = ({ cancelled }) => {
  const { viewer } = useViewerQuery();
  const { appLang } = useDeviceLang();

  if (!viewer?.subscription) return null;

  return (
    <Wrap>
      {cancelled ? <Trans>Subscription is cancelled and will expire on</Trans> : <Trans>Subscribed until</Trans>}:{" "}
      {addSeconds(new Date(), viewer?.subscription?.timeToExpire).toLocaleDateString(appLang, {
        year: "numeric",
        month: "long",
        day: "numeric"
      })}
    </Wrap>
  );
};

export default SubscribedUntil;
