import React from "react";
import styled from "styled-components/macro";
import MammothWithBubble, { MammothVariant } from "./MammothWithBubble";
import { onboardingStepsData as initialOnboardingStepsData, StepScreenId, TranslatedText } from "./onboardingStepsData";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardingStep from "./OnboardingStep";
import { getStepHash, ONBOARDING_HASH_PREFIX } from "./OnboardingRedirect";
import { dispatchEvent, EVENT } from "tools/events";
import useOnboardingData from "hooks/useOnboardingData";
import { ONBOARDING } from "root/RootRouter.config";
import { useGetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { usePrevious } from "react-use";

const MamothWrap = styled.div<{ $isSmall: boolean }>`
  padding: 0 30px;
  display: flex;
  align-items: ${({ $isSmall }) => ($isSmall ? "flex-start" : "center")};
  width: ${({ $isSmall }) => ($isSmall ? "100%" : "auto")};
`;

const MAX_PROGRESS_VALUE = 100;

const progressFunction = (stepIndex: number, totalSteps: number): number => {
  if (stepIndex <= 0 || totalSteps <= 0) {
    return 0; // first step not visible and also because of square root
  }

  if (totalSteps - 1 <= stepIndex) {
    return 1;
  }

  const normalizedProgress = stepIndex / totalSteps;
  const progress = Math.sqrt(normalizedProgress); // decrease progress increase over time
  return Math.min(Math.max(progress, 0), 1);
};

const calculateProgressValue = (currentStepIndex: number, totalSteps: number): number => {
  const progressForCurrentStep = progressFunction(currentStepIndex, totalSteps);
  const progress = Math.min(progressForCurrentStep * MAX_PROGRESS_VALUE, MAX_PROGRESS_VALUE);
  return progress;
};

const OnboardingSteps: React.FC = () => {
  const [onboardingStepsData, setOnboardingStepsData] = React.useState<typeof initialOnboardingStepsData | undefined>(
    undefined
  );
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const isDeviceDialogOpen = useGetState("deviceUsersDialogOpen");
  const progressValue = calculateProgressValue(
    currentStepIndex,
    (onboardingStepsData?.findIndex((step) => step.screenId === StepScreenId.ACHIEVEMENTS) ?? -1) + 1
  );
  const currentStep = currentStepIndex > -1 && onboardingStepsData ? onboardingStepsData[currentStepIndex] : null;
  const prevStepIndex = usePrevious(currentStepIndex);

  const { getOnboardingData } = useOnboardingData();
  const { latestStepReached } = getOnboardingData();
  const [canContinue, setCanContinue] = React.useState(false);
  const submitFunctionRef = React.useRef<() => Promise<boolean>>();
  const navigate = useNavigate();
  const location = useLocation();
  const { getFlag } = useViewerQuery();
  const autoContinue = getFlag("abtest_onboardingAutoContinue")?.value === "on";

  React.useEffect(() => {
    const noFirstWeek = getFlag("abtest_onboardingNoFirstWeek")?.value === "on";
    const shorterWelcome = getFlag("abtest_onboardingShorterWelcome")?.value === "on";
    const newQuestions = getFlag("abtest_onboardingNewQuestions")?.value === "on";
    const enableBack = getFlag("abtest_onboardingEnableBack")?.value === "on";

    setOnboardingStepsData(
      initialOnboardingStepsData
        .filter((step) => (noFirstWeek ? step.screenId !== StepScreenId.FIRST_WEEK : true))
        .filter((step) => (shorterWelcome ? step.screenId !== StepScreenId.WELCOME_2 : true))
        .filter((step) =>
          newQuestions
            ? true
            : step.screenId !== StepScreenId.QUESTIONS_MOTIVATION && step.screenId !== StepScreenId.QUESTIONS_AGE
        )
        .map((step) => {
          if (shorterWelcome && step.screenId === StepScreenId.WELCOME) {
            return {
              ...step,
              motivation: (
                <TranslatedText>
                  Hi, I am <b>Memo</b>.<br />
                  Let's improve your vocabulary!
                </TranslatedText>
              )
            };
          }
          if (
            enableBack &&
            initialOnboardingStepsData.indexOf(step) <
              initialOnboardingStepsData.findIndex((s) => s.screenId === StepScreenId.COURSE_PREPARATION)
          ) {
            return {
              ...step,
              canGoBack: true
            };
          }
          return step;
        })
    );
  }, [getFlag]);

  const handleBack = () => {
    if (!onboardingStepsData) return;

    const targetStep =
      currentStepIndex === 0 ? "/" : ONBOARDING.url() + getStepHash(onboardingStepsData[currentStepIndex - 1].screenId);

    navigate(targetStep);
  };

  const handleContinue = async () => {
    if (!onboardingStepsData) return;
    if (submitFunctionRef.current) {
      const res = await submitFunctionRef.current?.();
      if (!res) return;
    }
    setCanContinue(false);

    submitFunctionRef.current = undefined;
    navigate(ONBOARDING.url() + getStepHash(onboardingStepsData[currentStepIndex + 1].screenId));
  };

  const handleCanSubmitChange = (canContinue: boolean, submitFunction?: () => Promise<boolean>) => {
    setCanContinue(canContinue);
    submitFunctionRef.current = canContinue ? submitFunction : undefined;
  };

  React.useEffect(() => {
    if (latestStepReached === StepScreenId.WELCOME) {
      dispatchEvent(EVENT.refreshNotifications);
    }
  }, [latestStepReached]);

  React.useEffect(() => {
    if (!onboardingStepsData) return;
    const stepName = location.hash.replace(ONBOARDING_HASH_PREFIX, "");
    const stepIndex = onboardingStepsData.findIndex((step) => step.screenId === stepName);
    setCurrentStepIndex(stepIndex);
  }, [location, onboardingStepsData]);

  React.useEffect(() => {
    if (!onboardingStepsData) return;
    // Determines if the user has navigated back to a step they shouldn't be able to access
    // Returns true if the user should be redirected to their previous valid step
    const isBackRestricted = () => {
      if (currentStepIndex <= 0) return false; // unknown step or first step
      if (prevStepIndex === undefined) return false; // No previous step recorded
      if (prevStepIndex <= currentStepIndex) return false; // Not moving backwards
      if (onboardingStepsData[prevStepIndex]?.canGoBack) return false; // Step allows going back
      return true; // User has moved back to a restricted step
    };

    if (isBackRestricted()) {
      navigate(ONBOARDING.url() + getStepHash(onboardingStepsData[prevStepIndex!].screenId));
    }
  }, [prevStepIndex, currentStepIndex, onboardingStepsData, navigate]);

  if (!currentStep || isDeviceDialogOpen || !onboardingStepsData) {
    return null;
  }

  return (
    <OnboardingStep
      onBack={handleBack}
      showBackButton={!!currentStep.canGoBack}
      showProgressBar={!currentStep.hideProgressBar}
      progressValue={progressValue}
      buttonDisabled={currentStep.optional ? false : !canContinue}
      showContinue={
        autoContinue // after removing abtest set hideSubmitButton in configuration onboardingStepsData
          ? currentStep.componentProps?.multipleAnswers === false
            ? false
            : !currentStep.hideSubmitButton
          : !currentStep.hideSubmitButton
      }
      buttonOnClick={handleContinue}
    >
      <>
        {currentStep.motivation && (
          <MamothWrap
            $isSmall={currentStep.component && !currentStep.forceLargeMammoth ? true : false}
            key={currentStep.screenId}
          >
            <MammothWithBubble
              variant={
                currentStep.component && !currentStep.forceLargeMammoth ? MammothVariant.Small : MammothVariant.Large
              }
            >
              {currentStep.motivation}
            </MammothWithBubble>
          </MamothWrap>
        )}
        {currentStep.component && (
          <currentStep.component
            onRequestSubmit={handleContinue}
            onCanSubmitChange={handleCanSubmitChange}
            {...currentStep.componentProps}
          />
        )}
      </>
    </OnboardingStep>
  );
};

export default OnboardingSteps;
