/**
 * @generated SignedSource<<bb8fe17bb8d90f08e132fe1b7d223b4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionPlatform = "android" | "browser" | "ios" | "lemon" | "%future added value";
export type InviteAcceptDialogMutation$variables = {
  hash?: string | null;
};
export type InviteAcceptDialogMutation$data = {
  readonly inviteAccept: {
    readonly addedMonths: number | null;
    readonly inviter: {
      readonly deviceId: string | null;
      readonly name: string | null;
    } | null;
    readonly viewer: {
      readonly inviter: {
        readonly deviceId: string | null;
        readonly name: string | null;
      } | null;
      readonly subscriptions: ReadonlyArray<{
        readonly family: boolean;
        readonly platform: SubscriptionPlatform;
        readonly storeId: string;
        readonly timeToExpire: number;
        readonly transactionId: string;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_friends">;
    };
  } | null;
};
export type InviteAcceptDialogMutation = {
  response: InviteAcceptDialogMutation$data;
  variables: InviteAcceptDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hash"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "hash"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "inviter",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "addedMonths",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "inviter",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAcceptDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteAcceptRes",
        "kind": "LinkedField",
        "name": "inviteAccept",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "viewerQuery_friends"
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteAcceptDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InviteAcceptRes",
        "kind": "LinkedField",
        "name": "inviteAccept",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Friends",
                "kind": "LinkedField",
                "name": "friends",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Friend",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "friend",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "picture",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Xps",
                            "kind": "LinkedField",
                            "name": "xps",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "today",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentStreak",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasOnlyFewFriends",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shouldBeConfirmed",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50b6fa77eafd6bbe21b4b1e6762e1271",
    "id": null,
    "metadata": {},
    "name": "InviteAcceptDialogMutation",
    "operationKind": "mutation",
    "text": "mutation InviteAcceptDialogMutation(\n  $hash: String\n) {\n  inviteAccept(hash: $hash) {\n    viewer {\n      ...viewerQuery_friends\n      inviter {\n        name\n        deviceId\n        id\n      }\n      subscriptions {\n        transactionId\n        timeToExpire\n        platform\n        storeId\n        family\n      }\n      id\n    }\n    inviter {\n      name\n      deviceId\n      id\n    }\n    addedMonths\n  }\n}\n\nfragment viewerQuery_friends on User {\n  friends {\n    edges {\n      friend {\n        id\n        name\n        picture\n        xps {\n          total\n          today\n          currentStreak\n        }\n      }\n      hasOnlyFewFriends\n      shouldBeConfirmed\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ccdfe879f4573ea5810cd659fc0d584";

export default node;
