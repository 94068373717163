import React from "react";
import styled from "styled-components/macro";
import { TextField } from "../../../styled/TextField";
import { graphql } from "babel-plugin-relay/macro";
import { QueryOptions, useQuery } from "relay-hooks";
import Loader from "components/other/Loader";
import { VideoSearchQuery } from "./__generated__/VideoSearchQuery.graphql";
import { langs } from "../../../sharedJs__generated/langsList";
import VideoItem from "./VideoItem";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { VideoType } from "./VideoItem";
import { Trans, useTranslation } from "react-i18next";
import { flag } from "../../../tools/langs";
import { useTranslateQuery } from "../../../queries/translateQuery";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const qry = graphql`
  query VideoSearchQuery($search: String!, $lang: String!) {
    videoYTSearch(search: $search, lang: $lang) {
      videoId
      title
      description
      videoLang
      duration
      existingUri
    }
  }
`;

const Wrap = styled.div``;
const Msg = styled.div<{ red?: boolean }>`
  color: ${({ theme, red }) => (red ? "red" : theme.duo.color.textGrey)};
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.35em;
  text-align: center;
  margin: 15px 10px;
`;
const SPageviewIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.duo.color.primary};
  font-size: 26px;
`;
const Suggestions = styled.div`
  display: flex;
  margin: -9px -2px 3px;
  overflow-x: auto;
`;
const Suggestion = styled.div`
  display: inline-block;
  background: white;
  border-radius: 20px;
  padding: 2px 10px;
  margin: 2px 1px;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  box-shadow: 1px 1px 5px #0001;
  white-space: nowrap;
  cursor: pointer;
`;

export const VIDEO_SEARCH_TERM = "term";

type Props = {
  lang: string;
  onChange: (videoItem: VideoType) => void;
};

const VideoSearch: React.FC<Props> = ({ lang, onChange }) => {
  const [searchParams] = useSearchParams();
  const term = searchParams.get(VIDEO_SEARCH_TERM);
  const [searchVal, setSearchVal] = React.useState(term || "");
  const [search, setSearch] = React.useState("");
  const { t } = useTranslation();
  const sugs = `easy conversation\n${langs[lang].label} pronunciation`;
  const { translate: suggestions } = useTranslateQuery({ from: sugs, fromLang: "en", toLang: lang }) || sugs;

  // network-only to detect already published videos
  const opts: QueryOptions = { skip: !search, fetchPolicy: "network-only" };
  const { data, isLoading } = useQuery<VideoSearchQuery>(qry, { search, lang }, opts);

  const videos = data?.videoYTSearch;

  useEffectOnce(() => {
    if (searchVal) setSearch(searchVal);
  });

  return (
    <Wrap>
      <TextField
        name="name"
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
        label={t("Search on YouTube...")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SPageviewIcon onClick={() => setSearch(searchVal)} />
            </InputAdornment>
          )
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearch(searchVal);
            e.stopPropagation();
          }
        }}
      />
      {!searchVal && (
        <Suggestions>
          {[
            ...(suggestions || "easy conversation").split("\n"),
            `${langs[lang].label} language lesson`,
            `${langs[lang].label} pronunciation`
          ]?.map((s, i) => (
            <Suggestion
              key={i}
              onClick={() => {
                setSearchVal(s);
                setSearch(s);
              }}
            >
              {s}
            </Suggestion>
          ))}
        </Suggestions>
      )}

      {!videos && (
        <Msg>
          <Trans>Search for videos which have captions available in target language.</Trans>
          {lang !== "en" && (
            <p>
              <Trans>For better results, search in the target language</Trans> {flag(lang)}.
            </p>
          )}
        </Msg>
      )}

      {isLoading && <Loader />}

      {videos?.length === 1 && !videos[0].videoLang.startsWith(lang) && (
        <Msg red>
          The video has only english captions and you can add it because you are admin, so check if there are some
          target language captions.
        </Msg>
      )}

      {videos?.length
        ? videos.map((v) => <VideoItem key={v.videoId} onPick={() => onChange(v)} video={v} />)
        : videos?.length === 0 && (
            <Msg red>
              <Trans>
                We searched the first 50 results on YouTube, but none have closed-captions in the target language
              </Trans>{" "}
              ({langs[lang]?.label}){/* We didn't find {langs[lang]?.label} closed-captions for this video. */}
            </Msg>
          )}
    </Wrap>
  );
};

export default VideoSearch;
