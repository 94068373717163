import React from "react";
import { isEqual } from "lodash";
import { useMutation } from "relay-hooks";
import { createSubscriptionQL } from "../../../queries/users/createSubscription";
import { isAndroid, isIos } from "../../../tools/device";
import { useGetState, useSetState, Subscriptions, SubType } from "../../../components/ReduxProvider";
import { createSubscriptionMutation } from "../../../queries/users/__generated__/createSubscriptionMutation.graphql";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { useCounter, useDebounce, useEffectOnce } from "react-use";
import { logEvent, logPurchase } from "../../../tools/analyticEvents";
import { useSubscriptions } from "./subscriptionHooks";
import { Debug, SubscriptProduct, SubscriptTransaction } from "../../../sharedJs__generated/globalTypes";
import { SUB, StoreId, subsOnPlatform, useStoreIds } from "./subscriptionIds";
import { sentryCapture } from "sentry/sentry";
import { snackbar } from "tools/events";
import { useDBLog } from "hooks/useDBLog";
import { SUBDBLOG } from "sharedJs__generated/constants";

const IOS_ROOT_OFFER = "$";

const SubscriptionRegister: React.FC<{}> = () => {
  const [createSubscription] = useMutation<createSubscriptionMutation>(createSubscriptionQL);
  const setSubscriptions = useSetState("subscriptions");
  const subscription = useGetState("subscriptions");
  const {
    viewer,
    subscriptionFlags: { hadIosSubscription }
  } = useViewerQuery();
  const [products, setProducts] = React.useState<Subscriptions>({});
  const platform = isAndroid() ? "android" : isIos() ? "ios" : "browser";
  const { store, storePlatform } = useSubscriptions();
  const purchasedOffer = useGetState("purchasedOffer");
  const setPurchasedOffer = useSetState("purchasedOffer");
  const [transaction, setTransaction] = React.useState<SubscriptTransaction>();
  const boughtAlready = useGetState("boughtAlready");
  const setBoughtAlready = useSetState("boughtAlready");
  const setOrderOpened = useSetState("orderOpened");
  const dblog = useDBLog();
  const setAlreadyPurchasedBy = useSetState("alreadyPurchasedBy");
  const alreadyPurchasedBy = useGetState("alreadyPurchasedBy");
  const [purchasesCounter, { inc: incCounter }] = useCounter(0);

  const { storeIdFamily, storeIdYear } = useStoreIds();

  const handleUpdated = (product: SubscriptProduct) => {
    // update every product change which will be debounced later
    if (product?.offers && product.canPurchase) {
      setProducts((p) => ({
        ...p,
        ...product.offers.reduce((acc, offer, i) => {
          const isTrial = offer.pricingPhases[0].priceMicros === 0 && !(isIos() && hadIosSubscription); // on ios we can't detect trial from store data
          const pricing = offer.pricingPhases.find((p) => p.priceMicros !== 0);
          if (!pricing) {
            sentryCapture("SubscriptionRegister - no pricing found", { offer });
            return acc;
          }

          const storeId = (
            product.offers.length === 1 || offer.id === IOS_ROOT_OFFER ? product.id : offer.id
          ) as StoreId;

          product.offers.length > 1 && console.log(storeId);

          if (!SUB.find((s) => s.id === storeId)) {
            console.error("SubscriptionRegister not existing storeId", { storeId });
            return acc;
          }
          const subscription: SubType = {
            storeId: storeId,
            price: pricing.price,
            priceMicros: pricing.priceMicros,
            canPurchase: product.canPurchase,
            countryCode: product.countryCode,
            currency: pricing.currency,
            isTrial
          };
          return { ...acc, [storeId]: subscription };
        }, {})
      }));
    }
  };

  // debounced settings products as subscriptions in redux
  useDebounce(
    () => {
      if (viewer && products[storeIdYear] && !isEqual(subscription, products)) {
        setSubscriptions(products);
      }
    },
    10,
    [products]
  );

  // that's also called on app-start
  // debouncing, because iOS on restore-purhcases calls this function multiple times (at least on simulator)
  useDebounce(
    () => {
      if (!transaction) return;
      if (viewer?.subscriptions?.find((s) => s.transactionId === transaction.transactionId)) return;
      if (alreadyPurchasedBy?.transactionId === transaction.transactionId) return;
      setTransaction(undefined);
      if (purchasesCounter > 4) {
        sentryCapture("SubscriptionRegister is looping", { transaction, email: viewer?.email });
        return; // this is probably only happening sometimes on Petr's local, but let's keep it for some more time
      }
      incCounter();

      const transactionId = transaction.transactionId;
      let productId = transaction.products[0].id as StoreId;

      // Looking for valid (null=valid) offer.orderedId = clicked on in this session | finishedId = purchased previously
      let offerId =
        purchasedOffer?.orderedId === null || purchasedOffer?.orderedId
          ? purchasedOffer?.orderedId
          : purchasedOffer?.finishedId;
      if (offerId && offerId.startsWith(productId)) {
        productId = offerId;
      }

      // it's often issue, that on android it won't carry the offerId over Redux,
      // but it will be eventually fixed on BE so here we just use the most common offer year@p1y
      if (isAndroid() && productId === "year") {
        offerId = "year@p1y";
        productId = "year@p1y";
      }

      if (offerId || offerId === null) {
        setPurchasedOffer({ finishedId: offerId });
      }

      const oneSubscription = subscription?.[productId];
      if (!oneSubscription) {
        snackbar("Unexpected error. Restart the App and try again.", { severity: "error" });
        dblog(SUBDBLOG, "ERROR: loading subscriptions failed - recommend App reinstall", { subscription });
        return;
      }

      const debug: Debug = {
        id: productId,
        transaction:
          platform === "android"
            ? { id: transactionId, purchaseToken: transaction.nativePurchase!.purchaseToken }
            : { id: transactionId, appStoreReceipt: transaction.parentReceipt!.nativeData.appStoreReceipt },
        priceMicros: oneSubscription.priceMicros,
        currency: oneSubscription.currency,
        original: transaction,
        // using fulltext for older subscription cases where it's not prolonged automatically for some reason
        family: productId === storeIdFamily || productId.includes("family"),
        version: "13"
      };

      createSubscription({
        variables: {
          platform,
          debug: JSON.stringify(debug)
        },
        onCompleted: (props) => {
          if (props?.createSubscription?.alreadySubscribedBy) {
            setAlreadyPurchasedBy({ email: props.createSubscription.alreadySubscribedBy, transactionId });
            return;
          }
          if (props?.createSubscription?.user?.subscriptions?.[0] && debug.priceMicros && debug.currency) {
            const subscription = props.createSubscription.user.subscriptions[0];
            if (boughtAlready === productId) {
              sentryCapture("SubscriptionRegister - repeated purchase", { transactionId, props, productId });
              dblog(SUBDBLOG, `ERROR repeated purchase`, { subscription });
            } else {
              logEvent("trial_or_purchase"); // in case we want to target only this events regardless of revenue
              logPurchase({ price: subscription.czk, currency: "CZK" });
              dblog(SUBDBLOG, `Confirmed purchase [${subscription.storeId}]`, Infinity);
            }
            setBoughtAlready(productId);
          }
          setOrderOpened(undefined);
        },
        onError: ({ message }) => {
          dblog(SUBDBLOG, `Error on validaton -> ask user to "restore purchases"`, { message });
        }
      });
    },
    100,
    [transaction, subscription, purchasedOffer, viewer, alreadyPurchasedBy, boughtAlready, purchasesCounter]
  );

  useEffectOnce(() => {
    // subscription needs loaded viewer to proceed, store and register only once
    if (!viewer || !store || store.get(storeIdYear)) return;

    store.register(
      subsOnPlatform().map((sub) => ({
        id: sub.id,
        type: store.PAID_SUBSCRIPTION,
        platform: storePlatform
      }))
    );
    store
      .when()
      .updated(handleUpdated) // registering products into redux
      .approved((t: SubscriptTransaction) => {
        if (t.transactionId === "appstore.application") return;
        setTransaction(t);
        t.finish();
      });

    store.error((err) => {
      console.error("store error", err);
      setPurchasedOffer({ ...purchasedOffer, orderedId: undefined });
      setOrderOpened(undefined);
    });

    store.initialize([storePlatform]);
  });

  return null;
};

export default SubscriptionRegister;
