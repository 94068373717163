import { useRef } from "react";
import { useSelector } from "react-redux";
import { useDBLog } from "hooks/useDBLog";
import { useEvent } from "react-use";
import { selectDeviceId, useGetState } from "components/ReduxProvider";
import { EVENT } from "tools/events";
import { v4 as uuid } from "uuid";
import { isApp } from "tools/device";

export type LogUserActionDetail = {
  action: UserAction;
  detail?: Record<string, string | number | boolean>;
};

export enum UserAction {
  userAuthorized = "user_authorized",
  userAuthorizedFailed = "user_authorized_failed",
  signupVisited = "signup_visited"
}

const USER_ACTION_LOG = "user_action";

const UserActionsLogger = () => {
  const storageDeviceId = useSelector(selectDeviceId);
  const dblog = useDBLog();
  const sessionId = useRef<string | null>(uuid());
  const abtest_newReassurance2 = useGetState("abtest_newReassurance2");

  useEvent(EVENT.logUserAction, (e: CustomEvent<LogUserActionDetail>) => {
    if (!isApp()) return; // we do not log user actions on web
    const { action, detail } = e.detail;
    dblog(USER_ACTION_LOG, action, {
      sessionId: sessionId.current,
      deviceId: storageDeviceId,
      abtest_newReassurance2,
      ...detail
    });
  });

  return null;
};

export default UserActionsLogger;
