import React from "react";
import { sentryCapture } from "sentry/sentry";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { isBrowser, isIos } from "tools/device";
import { StoreId, SUB, useStoreIds } from "./subscriptionIds";
import { useIdle } from "react-use";
import { useDBLog } from "hooks/useDBLog";
import { MOTIVATIONHINT, SUBDBLOG } from "sharedJs__generated/constants";
import { useLemonStore } from "./LemonStore";

export const useSubscribe = () => {
  const { storeIdWithSale } = useSaleExpiration();
  const { store, storePlatform } = useSubscriptions();
  const purchasedOffer = useGetState("purchasedOffer");
  const setPurchasedOffer = useSetState("purchasedOffer");
  const orderOpened = useGetState("orderOpened");
  const setOrderOpened = useSetState("orderOpened");
  const isIdle = useIdle(5e3);
  const dblog = useDBLog();

  React.useEffect(() => {
    // end the order proces after some time (enable subsribe button again)
    if (!isIdle && orderOpened && Date.now() - orderOpened > 5e3) {
      setOrderOpened(undefined);
    }
  }, [isIdle, orderOpened, setOrderOpened]);

  return (storeIdParam?: StoreId) => {
    const storeId = storeIdParam || storeIdWithSale;
    if (!orderOpened) {
      if (!store) return sentryCapture(`Subscription ERROR - no store defined in order`);

      // subscription is either root-one (year on android or all on iOS) or basePlan (on android)
      const subInfo = SUB.find((s) => s.id === storeId);

      if (isBrowser() && subInfo) {
        store.getLemonCheckoutLink(subInfo.id);
      } else {
        const rootId = subInfo?.masterId || storeId;
        const offerId = subInfo?.masterId ? storeId : null;
        const product = store.get(rootId, storePlatform);

        if (product) {
          const offer = product.getOffer(offerId || "$"); // ios needs "$" in case there is promo-offer in the store (android consumes anything)
          // <-- here we might add promo signature for ios once we debug it correctly and it's needed (see generateIosPromoSignature.ts)
          offer.order();
          !isIos() && setPurchasedOffer({ ...purchasedOffer, orderedId: offerId });
          dblog(SUBDBLOG, "clicked on subscribe", { rootId, offerId });
        } else {
          sentryCapture(`Subscription ERROR - store get failed 2`, { storeId, storePlatform, product });
        }
        setOrderOpened(Date.now());
      }
    }
  };
};

const SALE_DEFAULT_DISCOUNT = 40;
const SALE_DEFAULT_TITLE = "Limited offer";

export const useSaleExpiration = () => {
  const { hintWasSeen, whenWasHintSeen } = useViewerQuery();
  const { storeIdYear, storeIdFamily } = useStoreIds();

  // BACKUP-SALE-FEATURE
  // const { userSale } = useUserSale();
  // if (userSale && userSale.id === "blackFriday") {
  //   return {
  //     saleExpiration: Math.floor(userSale.remaining / 1000),
  //     saleActive: true,
  //     storeIdWithSale: storeIdYear,
  //     familyIdWithSale: storeIdFamily,
  //     saleDiscount: SALE_DEFAULT_DISCOUNT,
  //     saleTitle: "Black Friday",
  //     saleId: userSale.id
  //   };
  // }

  const saleLength: number = 5 * 60 * 60;
  const newUserSaleStart: number = whenWasHintSeen(MOTIVATIONHINT);
  const _saleExpiration: number = saleLength - Math.abs(Math.floor((Date.now() - newUserSaleStart) / 1000));
  const saleExpiration = _saleExpiration > 0 ? _saleExpiration : 0; // can be -Infinity when hint doesnt exists
  const afterTutorial: boolean = hintWasSeen(MOTIVATIONHINT) || hintWasSeen(TUTORIALDONE);
  // const saleActive: boolean = saleExpiration > 0 || !!viewer?.installReferrer
  const saleActive = true;
  const storeIdWithSale: StoreId = /*saleActive ||*/ afterTutorial ? storeIdYear : storeIdFamily;
  const familyIdWithSale: StoreId = storeIdFamily;

  // for debug
  // return {
  //   saleExpiration: saleLength,
  //   saleActive: true,
  //   storeIdWithSale: storeIdYear,
  //   familyIdWithSale: storeIdFamily,
  //   saleDiscount: SALE_DEFAULT_DISCOUNT,
  //   saleTitle: SALE_DEFAULT_TITLE
  // };

  return {
    saleId: undefined,
    saleExpiration,
    saleActive,
    storeIdWithSale,
    familyIdWithSale,
    saleDiscount: SALE_DEFAULT_DISCOUNT,
    saleTitle: SALE_DEFAULT_TITLE
  };
};

export const useSubscriptions = () => {
  const subscriptions = useGetState("subscriptions");
  const store = (window as any).CdvPurchase?.store;
  const Platform = (window as any).CdvPurchase?.Platform;
  const storePlatform = isIos() ? Platform?.APPLE_APPSTORE : Platform?.GOOGLE_PLAY;
  const { subscriptions: lemonSubscriptions, store: lemonStore } = useLemonStore();

  if (store) {
    // ios and android
    return { subscriptions, store, storePlatform };
  } else if (isBrowser()) {
    // lemon
    return { subscriptions: lemonSubscriptions, store: lemonStore, storePlatform: "lemon" };
  } else {
    return { subscriptions: null, store: null, storePlatform: null };
  }
};
