/**
 * @generated SignedSource<<161b04f575e40f0634140eed85eb79b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SubscriptionPlatform = "android" | "browser" | "ios" | "lemon" | "%future added value";
export type AdminUsersSearchQuery$variables = {
  search: string;
};
export type AdminUsersSearchQuery$data = {
  readonly searchUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly affiliate: string | null;
        readonly affiliateStats: {
          readonly payout: number;
        } | null;
        readonly appLang: string | null;
        readonly createdBeforeSeconds: number;
        readonly deviceId: string | null;
        readonly deviceOs: string | null;
        readonly email: string | null;
        readonly flags: ReadonlyArray<{
          readonly name: string;
          readonly updated: number;
          readonly value: string | null;
        }>;
        readonly id: string;
        readonly lastLoginBeforeSeconds: number;
        readonly name: string | null;
        readonly payouts: ReadonlyArray<{
          readonly amount: number | null;
        }>;
        readonly subscriptions: ReadonlyArray<{
          readonly family: boolean;
          readonly platform: SubscriptionPlatform;
          readonly storeId: string;
          readonly timeToExpire: number;
          readonly transactionId: string;
        }>;
        readonly timezone: string;
        readonly xps: {
          readonly currentStreak: number;
          readonly total: number;
        };
      } | null;
    } | null> | null;
  };
};
export type AdminUsersSearchQuery = {
  response: AdminUsersSearchQuery$data;
  variables: AdminUsersSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appLang",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceOs",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdBeforeSeconds",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastLoginBeforeSeconds",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affiliate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Xps",
  "kind": "LinkedField",
  "name": "xps",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentStreak",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "AffiliateStats",
  "kind": "LinkedField",
  "name": "affiliateStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payout",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUsersSearchQuery",
    "selections": [
      {
        "alias": "searchUsers",
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "__Admin__searchUsers_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFlags",
                    "kind": "LinkedField",
                    "name": "flags",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "payout",
                    "kind": "LinkedField",
                    "name": "payouts",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUsersSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v21/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "searchUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserFlags",
                    "kind": "LinkedField",
                    "name": "flags",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "payout",
                    "kind": "LinkedField",
                    "name": "payouts",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v21/*: any*/),
        "filters": [
          "search"
        ],
        "handle": "connection",
        "key": "Admin__searchUsers",
        "kind": "LinkedHandle",
        "name": "searchUsers"
      }
    ]
  },
  "params": {
    "cacheID": "ba8d90a1f68efb279dc049f947f4ec01",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "searchUsers"
          ]
        }
      ]
    },
    "name": "AdminUsersSearchQuery",
    "operationKind": "query",
    "text": "query AdminUsersSearchQuery(\n  $search: String!\n) {\n  searchUsers(search: $search, first: 20) {\n    edges {\n      node {\n        id\n        email\n        name\n        timezone\n        appLang\n        deviceId\n        deviceOs\n        createdBeforeSeconds\n        lastLoginBeforeSeconds\n        affiliate\n        flags {\n          name\n          value\n          updated\n          id\n        }\n        xps {\n          total\n          currentStreak\n        }\n        subscriptions {\n          transactionId\n          timeToExpire\n          platform\n          storeId\n          family\n        }\n        affiliateStats {\n          payout\n        }\n        payouts {\n          amount\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a750dac46907878260be383a9f365d6b";

export default node;
