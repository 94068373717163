import InternAdOverview from "components/ads/InternAdOverview";
import { useSeeHint } from "components/hint/Hint";
import React from "react";
import styled from "styled-components/macro";

export const FIRSTSUBSCRIPTION = "firstSubscription";
const Wrap = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${({ theme }) => theme.duo.maxWidth.normal};
  width: 100%;
  background: ${({ theme }) => theme.duo.color.lightGrey};
`;

const FirstSubscription: React.FC = () => {
  const seeHint = useSeeHint();

  return (
    <Wrap>
      <InternAdOverview onClose={() => seeHint(FIRSTSUBSCRIPTION)} />
    </Wrap>
  );
};

export default FirstSubscription;
