import { graphql } from "babel-plugin-relay/macro";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import * as Sentry from "@sentry/browser";
import { useDeviceLang } from "../hooks/deviceHooks";
import { useQuery } from "relay-hooks";
import { i18nextQuery } from "./__generated__/i18nextQuery.graphql";
import React from "react";
import { debounce, uniq } from "lodash";
import { useLocalStorage } from "react-use";
import { useSetState, useGetState } from "components/ReduxProvider";
import { useHandleUsedTranslations } from "./handleUsedTranslations";

// Source:2c515efa-895a-4ce6-8e79-198d0c3f700a
const trDeckId = "U291cmNlOjJjNTE1ZWZhLTg5NWEtNGNlNi04ZTc5LTE5OGQwYzNmNzAwYQ==";

type TranslationsStorage = { lang: string; releaseId: string; resource: { [key: string]: string } };

const i18nQL = graphql`
  query i18nextQuery($id: ID!, $backLang: String) {
    node(id: $id) {
      ... on Source {
        sCards(backLang: $backLang) {
          front
          hint
          back {
            value
          }
        }
      }
    }
  }
`;

let missingKeys: string[] = [];
const reportMissing = debounce(() => {
  const msg = `MissingKeys:\n\n${uniq(missingKeys).join("\n\n")}\n\n`;
  if (process.env.NODE_ENV !== "production") {
    console.error(msg);
  } else {
    Sentry.captureMessage(msg);
  }
  missingKeys = [];
}, 100);

const keySeparator: false = false;
const defaultInit = {
  lng: "XX",
  resources: {},
  keySeparator, // we do not use keys in form messages.welcome
  nsSeparator: "#:#",
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  saveMissing: false
  // debugging on local
  // ,saveMissing: true, missingKeyHandler: (lng, ns, key) => { missingKeys.push(key); reportMissing(); }
};
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(defaultInit);

type SkippingErrors = "not yet" | "yes" | "not anymore";
export const useI18NextIsReady = () => {
  const [isReady, setIsReady] = React.useState(false);
  const { appLang } = useDeviceLang();
  const releaseId = useGetState("releaseId");
  const [storaged, setStoraged, removeStoraged] = useLocalStorage<TranslationsStorage>("i18n");
  const skippingErrors = React.useRef<SkippingErrors>("not yet");
  // we are not sending refreshed translates to users on every new release,
  // but we wait for their first missingKey to send them new translates
  const validStorage = storaged?.lang === appLang; // && releaseId === storaged.releaseId;
  const skip = validStorage || !!process.env.REACT_APP_DISABLE_LOCALIZATION;
  const setLoaderGlobal = useSetState("loaderGlobal");
  const usedKeys = React.useRef<string[]>([]);

  useHandleUsedTranslations(usedKeys);

  const { data, isLoading } = useQuery<i18nextQuery>(
    i18nQL,
    { id: trDeckId, backLang: appLang },
    {
      fetchPolicy: "network-only",
      skip,
      networkCacheConfig: { force: true },
      fetchKey: releaseId,
      onComplete: () => (skippingErrors.current = "not anymore")
    }
  );
  const sCards = data?.node?.sCards;

  // display loader when user changes app lang
  React.useEffect(() => {
    if (storaged && storaged.lang !== appLang) {
      setLoaderGlobal(isLoading);
    }
  });

  React.useEffect(() => {
    if (!sCards && !validStorage) return;
    if (isReady && validStorage) return;

    let resource;
    if (validStorage) {
      resource = storaged.resource;
    } else {
      resource = sCards?.reduce((res, sCard) => {
        res[sCard.hint || sCard.front] = appLang === "en" ? sCard.front : sCard.back?.value;
        return res;
      }, {});

      setStoraged({ lang: appLang, releaseId, resource });
    }

    i18next.init({
      ...defaultInit,
      lng: appLang,
      resources: { [appLang]: { translation: resource } },
      saveMissing: true,
      missingKeyHandler: (lng, ns, key) => {
        if (skippingErrors.current === "not yet") {
          skippingErrors.current = "yes";
          setTimeout(removeStoraged);
        }

        if (skippingErrors.current === "yes") {
          return;
        }

        missingKeys.push(key);
        reportMissing();
      }
    });

    const originalTranslate = i18next.t.bind(i18next);
    i18next.t = (key, ...args) => {
      if (!usedKeys.current.includes(key)) usedKeys.current.push(key);
      return originalTranslate(key, ...args);
    };

    setIsReady(true);
  }, [sCards, appLang, storaged, setStoraged, validStorage, releaseId, isReady, removeStoraged]);

  return skip ? true : isReady;
};
