import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { Trans } from "react-i18next";
const BaseWrap = styled.div<{ $toggled: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ $toggled, theme }) => ($toggled ? theme.duo.palette.blueMediumLight : "white")};
  border: 1px solid ${({ theme }) => theme.duo.palette.blueMain};
`;

const RoundWrap = css`
  padding-left: 26px;
  padding-right: 26px;
  border-radius: 50px;
  font-size: 16px;
  min-height: 60px;
  gap: 10px;
  justify-content: space-between;
`;

const SquareWrap = css`
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  gap: 9px;
  flex-direction: column;
  justify-content: center;
`;

const ToggleItemWrap = styled(BaseWrap)<{ $itemStyleType: string }>`
  ${({ $itemStyleType }) => ($itemStyleType === "square" ? SquareWrap : RoundWrap)}
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrap = styled.div`
  flex: 1;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const ExtraContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type ItemData = {
  id: string;
  icon?: React.ReactNode;
  text?: string;
  extraContent?: React.ReactNode;
  translate?: boolean;
};

type Props = ItemData & {
  toggle?: boolean;
  onToggle?: (toggled: boolean) => void;
  itemStyleType?: "rounded" | "square";
};

const ToggleItem: React.FC<Props> = ({
  icon,
  text,
  extraContent,
  toggle,
  onToggle,
  itemStyleType = "rounded",
  translate = true
}) => {
  const [toggled, setToggled] = useState(toggle || false);

  useEffect(() => {
    if (toggle !== undefined) {
      setToggled(toggle);
    }
  }, [toggle]);

  const handleToggle = () => {
    const newToggledState = !toggled;
    setToggled(newToggledState);
    onToggle?.(newToggledState);
  };

  return (
    <ToggleItemWrap onClick={handleToggle} $toggled={toggled} $itemStyleType={itemStyleType}>
      {icon && <IconWrap>{icon}</IconWrap>}
      {text && <TextWrap>{translate ? <Trans>{text}</Trans> : text}</TextWrap>}
      {extraContent && <ExtraContentWrap>{extraContent}</ExtraContentWrap>}
    </ToggleItemWrap>
  );
};

export default ToggleItem;
